import React, { Component } from "react";
import "./App.css";
import axios from "axios";
import MetaVariavel from "./components/MetaVariavel";
import Vendas from "./components/Vendas";
import Resumo from "./components/Resumo";
import { connect } from "react-redux";
import FunilVendas from "./components/Panels/FunilVendas";
import Ranking from "./components/ranking/ranking";
import Orcado from "./components/Orcado";
import Notifications, {notify} from 'react-notify-toast'
import ProspeccaoClientes from "./components/Dashboard/ProspeccaoClientes";
import CarteiraClientes from "./components/Dashboard/CarteiraClientes";
import FaturamentoEEmNegociacao from "./components/Dashboard/FaturamentoEEmNegociacao";
import Faturamento from "./components/Panels/Faturamento";

import { REQUEST_URL } from "./config/urls";
import ClassificacaoABC from "./components/Panels/ClassificacaoABC";
import TicketMedio from "./components/Panels/TicketMedio";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login_codigo:"",
      login_id:"",
      dadosBruto: [],
      qtdSus: 0,
      qtdPros: 0,
      qtdCli: 0,
      qtdVendas: 0,
      vendedor: "",
      metaVendas: 5000,
      ordensProducao: [],
      metaVenda: [],
      chavesAnual: [],
      chartData: {},
      chartDataAnual: {},
      precoVendas: 0.0,
      chartDataVendas: {},
      chartDataVendasAnual: {},
      totalUltimoAno: {
        suspects: 0,
        prospect: 0,
        cliente: 0,
        valorVendas: 0,
        qtdVendas: 0,
        metaAnual: 0
      },
      todosVendedores: [],
      nomeVendedor: "",
      clientesUltOrc: [],
      clientesUltOrcTodos: [],
      clientesAtivos: [],
      clientesInativos: [],
      carteiraClientes: [],
      dadosPanelFaturamento: [],
      carregandoPanelFaturamento: false,
      selectAno: "",
      selectMes: "",
      labels: [],
      loading: true,
      opsFaturamento: [],
      chartDataMetaVariavel: {},
      tipoAcesso:"V",
      timeRanking:"2",
      considerarInativo:"12",
      opsFiltradas:[],
      carregandoMesAno: true,
      metaAnual: 0
    };
  }
  
  async componentDidMount () {
    let usuarioLogado = this.getUsuarioLogado(),
      vendedores = this.getVendedores();
    if (usuarioLogado) {
      this.setState({tipoAcesso: usuarioLogado.tipoAcesso, nomeVendedor: usuarioLogado.nome, timeRanking: usuarioLogado.timeRanking, vendedor: usuarioLogado.codigo, todosVendedores: vendedores});
      await this.buscarDados(usuarioLogado.codigo);
    }
  }
  
  componentDidUpdate() {
    if (this.state.qtdCli || this.state.qtdSus || this.state.qtdPros) {
      if (!window.iniciouEasyChart) {
        window.iniciarEPC();
      }
    }
  }
  
  async considerarInativo () {
    this.setState({ carregandoMesAno: true });

    let { considerarInativo } = this.state;

    if (considerarInativo === "12") {
      considerarInativo = "6";
    } else if (considerarInativo === "6") {
      considerarInativo = "3";
    } else {
      considerarInativo = "12";
    }

    this.setState({ considerarInativo }, async () => {
      await this.getCarteiraClientes();
      await this.getClientesAtivos();
      await this.getClientesInativos();
      this.setState({ carregandoMesAno: false });
    });
  }
  
  async montarDadosGraficoVendas(ops, ano, intervalo, mes = "-2") {
    let opsFiltradas =[];
    let chaves = this.getLabels(ano, mes, intervalo);
    chaves = await this.zerarValoresVendas(chaves);
    for (let i = 0; i < ops.length; i++) {
      let dt = this.sqlDateToJsDate(ops[i].DtEmissaoOp);
      for (let k = 0; k < chaves.length; k++) {
        if (
          chaves[k].data.getMonth() === dt.getMonth() &&
          chaves[k].data.getYear() === dt.getYear()
        ) {
          chaves[k].qtdVendas++;
          chaves[k].precoVendas += ops[i].PrecoTotal;
          chaves[k].cmarginal += ops[i].CMarginalValor;
          opsFiltradas.push(ops[i]);
        }
      }
    }
    this.setState({opsFiltradas});
    let c1 = [];
    let dados1 = [];
    let dados2 = [];

    for (let i = 0; i < chaves.length; i++) {
      c1.push(
        chaves[i].data.toLocaleDateString("pt-br", {
          year: "numeric",
          month: "short"
        })
      );
      dados1.push(chaves[i].qtdVendas);
      dados2.push(chaves[i].precoVendas.toFixed(2));
    }

    let chartData = {
      labels: c1,
      datasets: [
        {
          label: "quantidade",
          yAxisID: "y-axis-1",
          data: dados1,
          backgroundColor: "rgba(255, 99, 132,1)",
          borderColor: "rgba(255, 99, 132,1)",
          cubicInterpolationMode: "monotone",
          hidden: true,
        },
        {
          label: "faturamento",
          yAxisID: "y-axis-2",
          data: dados2,
          backgroundColor: "rgba(255, 205, 96,1)",
          borderColor: "rgba(255, 205, 96,1)"
        }
      ]
    };
    this.setState({
      chartDataVendas: chartData,
      chartDataVendasAnual: chartData
    });
  }
  
  montarDadosGrafico(dadosBruto) {
    let chaves = [];
    var data = new Date();
    data = new Date(data.setMonth(data.getMonth() + 1));
    for (let i = 0; i < 12; i++) {
      chaves.push({ data, qtdSus: 0, qtdPros: 0, qtdCli: 0 });
      data = new Date(data.setMonth(data.getMonth() - 1));
    }
    for (let i = 0; i < dadosBruto.length; i++) {
      let dt = new Date(dadosBruto[i].Dt);
      for (let k = 0; k < chaves.length; k++) {
        if (
          chaves[k].data.getMonth() === dt.getMonth() &&
          chaves[k].data.getYear() === dt.getYear()
        ) {
          if (dadosBruto[i].IDSEStatus === 31) {
            chaves[k].qtdSus++;
          } else if (dadosBruto[i].IDSEStatus === 32) {
            chaves[k].qtdPros++;
          } else if (dadosBruto[i].IDSEStatus === 33) {
            chaves[k].qtdCli++;
          }
        }
      }
    }

    this.setState({ chavesAnual: chaves });

    let c = [];
    let dados = [];
    let totalUltimoAno = this.state.totalUltimoAno;
    for (let i = 0; i < 12; i++) {
      c.push(
        chaves[i].data.toLocaleDateString("pt-br", {
          year: "numeric",
          month: "short"
        })
      );
      dados.push(chaves[i].qtdSus);
      totalUltimoAno.suspects += chaves[i].qtdSus;
    }
    let dados1 = [];
    for (let i = 0; i < 12; i++) {
      dados1.push(chaves[i].qtdPros);
      totalUltimoAno.prospect += chaves[i].qtdPros;
    }
    let dados2 = [];
    for (let i = 0; i < 12; i++) {
      dados2.push(chaves[i].qtdCli);
      totalUltimoAno.cliente += chaves[i].qtdCli;
    }
    this.setState({ totalUltimoAno });
    let chartData = {
      labels: c,
      xAxisID: "x",
      yAxisID: "y",
      datasets: [
        {
          label: "suspects",
          data: dados,
          backgroundColor: "rgba(255, 181, 62,0)",
          borderColor: "#ffb53e",
          cubicInterpolationMode: "monotone"
        },
        {
          label: "prospect",
          data: dados1,
          backgroundColor: "rgba(30, 191, 174,0)",
          borderColor: "#1ebfae"
        },
        {
          label: "cliente",
          data: dados2,
          backgroundColor: "rgba(48, 165, 255,0)",
          borderColor: "#30a5ff",
          lineTension: 0
        }
      ]
    };
    this.setState({ chartData, chartDataAnual: chartData });
  }
  
  filtrarDadosGraficoVendas(mes, ano) {
    let chavesMes = this.getLabels(ano, mes);
    let opsFiltradas =[];
    //console.log(chavesMes);
    chavesMes = this.zerarValoresVendas(chavesMes);
    let ops = this.state.ordensProducao;

    for (let i = 0; i < ops.length; i++) {
      let dt = this.sqlDateToJsDate(ops[i].DtEmissaoOp);
      for (let k = 0; k < chavesMes.length; k++) {
        if (
          chavesMes[k].data.getMonth() === dt.getMonth() &&
          chavesMes[k].data.getYear() === dt.getYear() &&
          chavesMes[k].data.getDate() === dt.getDate()
        ) {
          chavesMes[k].qtdVendas++;
          chavesMes[k].precoVendas += ops[i].PrecoTotal;
          chavesMes[k].cmarginal += ops[i].CMarginalValor;
          opsFiltradas.push(ops[i]);
        }
      }
    }
    this.setState({opsFiltradas});
    let c1 = [];
    let dados1 = [];
    let dados2 = [];
    //this.setState({labels:chavesMes});
    for (let i = 0; i < chavesMes.length; i++) {
      c1.push(
        chavesMes[i].data.toLocaleDateString("pt-br", { day: "numeric" })
      );
      dados1.push(chavesMes[i].qtdVendas);
      dados2.push(chavesMes[i].precoVendas.toFixed(2));
    }
    let chartData = {
      labels: c1,
      datasets: [
        {
          label: "quantidade",
          yAxisID: "y-axis-1",
          data: dados1,
          backgroundColor: "rgba(255, 99, 132,1)",
          borderColor: "rgba(255, 99, 132,1)",
          cubicInterpolationMode: "monotone"
        },
        {
          label: "faturamento",
          yAxisID: "y-axis-2",
          data: dados2,
          backgroundColor: "rgba(255, 205, 96,1)",
          borderColor: "rgba(255, 205, 96,1)"
        }
      ]
    };

    this.setState({
      chartDataVendas: chartData,
      chartDataVendasAnual: chartData
    });
  }
  
  async buscarDados(vendedor) {
    let { selectAno, selectMes } = this.state;

    selectAno = selectAno !== "" ? selectAno : new Date().getFullYear();
    selectMes = selectMes !== "" ? selectMes : new Date().getMonth();

    await this.setState({ loading: true, carregandoMesAno: true, selectAno, selectMes, carregandoPanelFaturamento: true }, async () => {
      
      await axios.get(REQUEST_URL + "todosStatusCliente/" + vendedor).then((response) => {
        this.setState({ dadosBruto: response.data });
        this.contarStatus(response.data);
        this.montarDadosGrafico(response.data);
      }).catch((error) => {
        console.log(error);
      });

      await axios.get(REQUEST_URL + "metaVenda/" + vendedor).then((response) => {
        this.setState({ metaVenda: response.data });
        this.calcularMeta(response.data, selectMes, selectAno, (selectAno.length <= 2 ? selectAno === "12" ? 1 : 2 : 0));
        this.calcularMetaAnual(this.state.metaVenda, "-2", selectAno);
      }).catch((error) => {
        console.log(error);
      });

      await this.getVendas(); 
      
      await this.calcularFaturamentoAnosAtualERetrasado();

      this.setState({ loading: false }, async () => {
        await this.getCarteiraClientes();
        await this.getClientesAtivos();
        await this.getClientesInativos();
        this.setState({ carregandoMesAno: false });
      });
    });
  }
  
  // montarDadosGraficoMetaVariavel(ops, ano, meta) {
  //   let chaves = this.getLabels(ano, "-2", 0);
  //   chaves = this.zerarValoresVendas(chaves);
  //   for (let i = 0; i < ops.length; i++) {
  //     let dt = this.sqlDateToJsDate(ops[i].DtEmissaoOp);
  //     for (let k = 0; k < chaves.length; k++) {
  //       if (
  //         chaves[k].data.getMonth() === dt.getMonth() &&
  //         chaves[k].data.getYear() === dt.getYear()
  //       ) {
  //         chaves[k].precoVendas += ops[i].PrecoTotal;
  //       }
  //     }
  //   }
  //   let vendaAcumulada = 0;
  //   let metaAnual = meta;
  //   let mesesProFim = chaves.length;
  //   for (let i = 0; i < chaves.length; i++) {
  //     chaves[i].metaVariavel = (metaAnual - vendaAcumulada) / mesesProFim;
  //     chaves[i].meta = metaAnual / chaves.length;
  //     vendaAcumulada += chaves[i].precoVendas;
  //     if (chaves[i].precoVendas > 0) mesesProFim--;
  //   }
  //
  //   let c1 = [];
  //   let dados1 = [];
  //   let dados2 = [];
  //   let dados3 = [];
  //
  //   let soma1 = 0;
  //   let soma2 = 0;
  //   for (let i = 0; i < chaves.length; i++) {
  //     c1.push(
  //       chaves[i].data.toLocaleDateString("pt-br", {
  //         year: "numeric",
  //         month: "short"
  //       })
  //     );
  //     dados1.push(chaves[i].meta.toFixed(2));
  //     soma1 += chaves[i].meta;
  //     dados2.push(chaves[i].precoVendas.toFixed(2));
  //     soma2 += chaves[i].metaVariavel;
  //     dados3.push(chaves[i].metaVariavel.toFixed(2));
  //   }
  //
  //   let chartData = {
  //     labels: c1,
  //     datasets: [
  //       {
  //         fill: false,
  //         label: "meta",
  //         data: dados1,
  //         backgroundColor: "#2D8DD6",
  //         borderColor: "#2D8DD6",
  //         cubicInterpolationMode: "monotone"
  //       },
  //       {
  //         fill: false,
  //         label: "real",
  //         data: dados2,
  //         backgroundColor: "#FF8B58",
  //         borderColor: "#FF8B58"
  //       },
  //       {
  //         fill: false,
  //         label: "ideal",
  //         data: dados3,
  //         backgroundColor: "#09B22E",
  //         borderColor: "#09B22E"
  //       }
  //     ]
  //   };
  //   // console.log(chartData);
  //   this.setState({ chartDataMetaVariavel: chartData });
  // }
  
  handleChangeAno = async (event) => {
    let valor = event.target.value;
    await this.setState({ selectAno: valor, selectMes: "-2", carregandoMesAno: true });
    await this.getVendas(1);
    await this.getClientesAtivos();
    await this.getClientesInativos();
    if (valor === "12") {
      this.montarDadosGraficoVendas(this.state.ordensProducao, valor, 1);
      this.calcularVendas(this.state.ordensProducao, -1, 1, 1);
      this.calcularMeta(this.state.metaVenda, -1, 1, 1);
    } else if (valor === "6") {
      this.montarDadosGraficoVendas(this.state.ordensProducao, valor, 2);
      this.calcularVendas(this.state.ordensProducao, -1, 1, 2);
      this.calcularMeta(this.state.metaVenda, -1, 1, 2);
    } else {
      this.montarDadosGraficoVendas(this.state.ordensProducao, valor, 0);
      this.calcularVendas(this.state.ordensProducao, "-2", valor, 0);
      this.calcularMeta(this.state.metaVenda, "-2", valor, 0);
    }
    this.calcularMetaAnual(this.state.metaVenda, "-2", valor);
    // this.montarDadosGraficoMetaVariavel(this.state.ordensProducao, this.state.selectAno, this.state.metaAnual);
    this.setState({carregandoMesAno: false});
  }

  handleChangeMes = async (event) => {
    let valor = event.target.value;
    await this.setState({ selectMes: valor, carregandoMesAno: true });
    await this.getVendas(1);
    await this.getClientesAtivos();
    await this.getClientesInativos();
    if (valor !== "-2") {
      this.filtrarDadosGraficoVendas(valor, this.state.selectAno);
      this.calcularVendas(this.state.ordensProducao, valor, this.state.selectAno, 0);
      this.calcularMeta(this.state.metaVenda, valor, this.state.selectAno, 0);
      this.calcularMetaAnual(this.state.metaVenda, "-2", valor);
      // this.montarDadosGraficoMetaVariavel(this.state.ordensProducao, this.state.selectAno, this.state.metaAnual);
    }
    this.setState({carregandoMesAno: false});
  }

  getDataInicio () {
    let { selectAno, selectMes } = this.state,
      dataInicio = `${selectAno}-${selectMes && Number(selectMes) >= 0 && Number(selectMes) <= 11 ? selectMes.length === 1 ? "0" + (Number(selectMes) + 1) : Number(selectMes) + 1 : "01"}-01`;
    if (selectAno.length <= 2) {
      dataInicio = new Date();
      dataInicio.setMonth(dataInicio.getMonth() - Number(selectAno));
      dataInicio = dataInicio.toLocaleDateString('pt-BR', {timeZone: 'UTC'}).split("/").reverse().join("-");
    }
    return dataInicio;
  }

  getDataFim () {
    let dataInicio = this.getDataInicio(),
      { selectAno, selectMes } = this.state,
      dataFim = dataInicio.split("-");
    
    if (selectAno.length <= 2) {
      let hoje = new Date().toLocaleDateString('pt-BR', {timeZone: 'UTC'}).split("/").reverse();
      dataFim = hoje;
    } else if (selectMes === "-2") {
      dataFim[1] = "12";
    }

    dataFim = new Date(dataFim[0], dataFim[1], 0);
    
    return new Date(dataFim).toLocaleDateString('pt-BR', {timeZone: 'UTC'}).split("/").reverse().join("-");
  }

  getVendas = async (faturamento = undefined) => {
    let dataInicio = this.getDataInicio(),
      { selectMes, selectAno } = this.state;
    await axios.get(`${REQUEST_URL}vendas/${dataInicio}/${this.state.vendedor}`).then((response) => {
      this.setState({ ordensProducao: response.data });
      // this.montarDadosGraficoMetaVariavel(response.data, selectAno, this.state.metaAnual);
      this.calcularVendas(response.data, selectMes, selectAno, (selectAno.length <= 2 ? selectAno === "12" ? 1 : 2 : 0));
      this.montarDadosGraficoVendas(response.data, selectAno, faturamento, selectMes); // OPS, FILTRO ANO, ULTIMOS 12 MESES
    }).catch((error) => {
      console.log(error);
    });
  }

  contarStatus(dadosBruto, mes) {
    let qtdSus = 0,
      qtdPros = 0,
      qtdCli = 0;
    if (mes) {
      for (let i = 0; i < dadosBruto.length; i++) {
        let dt = new Date(dadosBruto[i].Dt);

        if (
          dt.getMonth() === mes.getMonth() &&
          dt.getYear() === mes.getYear()
        ) {
          //console.log("eguals");
          if (dadosBruto[i].IDSEStatus === 31) {
            qtdSus++;
          } else if (dadosBruto[i].IDSEStatus === 32) {
            qtdPros++;
          } else if (dadosBruto[i].IDSEStatus === 33) {
            qtdCli++;
          }
        }
      }
    } else {
      let umAnoAtras = new Date();
      umAnoAtras.setFullYear(umAnoAtras.getFullYear() - 1);
      for (let i = 0; i < dadosBruto.length; i++) {
        let dt = new Date(dadosBruto[i].Dt);
        if (umAnoAtras <= dt) {
          if (dadosBruto[i].IDSEStatus === 31) {
            qtdSus++;
          } else if (dadosBruto[i].IDSEStatus === 32) {
            qtdPros++;
          } else if (dadosBruto[i].IDSEStatus === 33) {
            qtdCli++;
          }
        }
      }
    }
    this.setState({ qtdSus, qtdPros, qtdCli });
  }

  async calcularFaturamentoAnosAtualERetrasado () {
    let umAnoAtras = new Date(),
      precosMensais = [],
      hoje = new Date();
    
    umAnoAtras.setFullYear(umAnoAtras.getFullYear() - 1);
    umAnoAtras = new Date(umAnoAtras.getFullYear(), 0, 1);

    precosMensais.push({ano: umAnoAtras.getFullYear(), meses: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]});
    precosMensais.push({ano: hoje.getFullYear(), meses: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]});

    umAnoAtras = new Date(umAnoAtras).toLocaleDateString("pt-BR", {timeZone: "UTC"}).split("/").reverse().join("-");

    await axios.get(`${REQUEST_URL}vendas/${umAnoAtras}/${this.state.vendedor}`).then((response) => {
      response.data.forEach((op) => {
        let ano = new Date(op.DtEmissaoOp).getFullYear(),
          mes = new Date(op.DtEmissaoOp).getMonth();
        precosMensais.forEach((arr) => {
          if (arr.ano === ano) {
            arr.meses[mes] += op.SPreco;
          }
        });
      });
      this.setState({ carregandoPanelFaturamento: false, dadosPanelFaturamento: precosMensais });
    }).catch((erro) => {
      console.log(erro);
    })
  }

  async calcularVendas(ops, mes, ano, periodo) {
    let qtdVendas = 0,
      precoVendas = 0.0,
      opsFaturamento = [],
      { totalUltimoAno } = this.state;
    if (periodo === 1) {
      //Venda dos ultimos 12 meses
      let umAnoAtras = new Date();
      umAnoAtras.setMonth(umAnoAtras.getMonth() - 12);
      umAnoAtras.setHours(0,0,0,0);
      for (let i = 0; i < ops.length; i++) {
        let dt = this.sqlDateToJsDate(ops[i].DtEmissaoOp);
        if (umAnoAtras <= dt) {
          if(ops[i].IdWOStatus !== 6){
            qtdVendas++;
            precoVendas += ops[i].PrecoTotal;
            opsFaturamento.push(ops[i]);
          }
        }
      }
    } else if (periodo === 2) {
      //Venda dos ultimos 12 meses
      let seisMesesAtras = new Date();
      seisMesesAtras.setMonth(seisMesesAtras.getMonth() - 6);
      seisMesesAtras.setHours(0,0,0,0);
      for (let i = 0; i < ops.length; i++) {
        let dt = this.sqlDateToJsDate(ops[i].DtEmissaoOp);
        if (seisMesesAtras < dt) {
          if(ops[i].IdWOStatus !== 6){
            qtdVendas++;
            precoVendas += ops[i].PrecoTotal;
            opsFaturamento.push(ops[i]);
          }
        }
      }
    } else if (mes !== "-2" && mes !== -1) {
      //Calcula vendas do mes especifico
      ops.forEach(op => {
        let dt = this.sqlDateToJsDate(op.DtEmissaoOp),
          dtf = new Date(ano, mes, 1);
        if (dt.getMonth() === dtf.getMonth() && dt.getFullYear() === dtf.getFullYear()) {
          if(op.IdWOStatus !== 6){
            qtdVendas++;
            precoVendas += op.PrecoTotal;
            opsFaturamento.push(op);
          }
        }
      });
    } else {
      //Calcula vendas do ano especifico
      for (let i = 0; i < ops.length; i++) {
        let dt = new Date(ops[i].DtEmissaoOp);
        if (dt.getFullYear() === Number(ano)) {
          if(ops[i].IdWOStatus !== 6){
            qtdVendas++;
            precoVendas += ops[i].PrecoTotal;
            opsFaturamento.push(ops[i]);
          }
        }
      }
    }
    totalUltimoAno.valorVendas = precoVendas;
    totalUltimoAno.qtdVendas = qtdVendas;
    await this.setState({ qtdVendas, precoVendas, totalUltimoAno, opsFaturamento });
  }
  
  async calcularMeta(metas, mes, ano, periodo) {
    let meta = 0;
    if (periodo === 1) {
      let umAnoAtras = new Date();
      umAnoAtras.setMonth(umAnoAtras.getMonth() - 12);
      umAnoAtras.setHours(0,0,0,0);
      for (let i = 0; i < metas.length; i++) {
        let dt = this.sqlDateToJsDate(metas[i].Periodo);
        if (umAnoAtras < dt) {
          meta += metas[i].Meta;
        }
      }
      let totalUltimoAno = this.state.totalUltimoAno;
      totalUltimoAno.metaAnual = meta;
      this.setState({ totalUltimoAno });
    } else if (periodo === 2) {
      let seisMesesAtras = new Date();
      seisMesesAtras.setMonth(seisMesesAtras.getMonth() - 6);
      seisMesesAtras.setHours(0,0,0,0);
      for (let i = 0; i < metas.length; i++) {
        let dt = this.sqlDateToJsDate(metas[i].Periodo);
        if (seisMesesAtras < dt) {
          meta += metas[i].Meta;
        }
      }
      //let totalUltimoAno = this.state.totalUltimoAno;
      //totalUltimoAno.metaAnual = meta;
      //this.setState({totalUltimoAno});
    } else if (mes !== "-2" && mes !== -1) {
      //console.log("meta mes");
      for (let i = 0; i < metas.length; i++) {
        let dt = this.sqlDateToJsDate(metas[i].Periodo);
        if (dt.getMonth() === Number(mes) && dt.getFullYear() === Number(ano)) {
          meta += metas[i].Meta;
        }
      }
    } else {
      //console.log("meta ano");
      for (let i = 0; i < metas.length; i++) {
        let dt = this.sqlDateToJsDate(metas[i].Periodo);
        if (dt.getFullYear() === Number(ano)) {
          meta += metas[i].Meta;
        }
      }
    }
    await this.setState({ metaVendas: meta });
  }

  calcularMetaAnual(metas, mes, ano) {
    let meta = 0;
    for (let i = 0; i < metas.length; i++) {
      let dt = this.sqlDateToJsDate(metas[i].Periodo);
      if (dt.getFullYear() === Number(ano)) {
        meta += metas[i].Meta;
      }
    }
    this.setState({ metaAnual: meta });
  }
  
  sqlDateToJsDate(stringDt) {
    return new Date(Number(stringDt.substring(0, 4)), Number(stringDt.substring(5, 7)) - 1, Number(stringDt.substring(8, 10)));
  }
  
  getLabels(ano, mes, faturamento) {
    if (faturamento === 1) {
      //returnar os ultimos 12 meses
      let labels = [];
      var data = new Date();
      data = new Date(data.setMonth(data.getMonth() + 1));
      for (let i = 0; i < 12; i++) {
        labels.push({ data });
        data = new Date(data.setMonth(data.getMonth() - 1));
      }
      this.setState({ labels });
      return labels;
    } else if (faturamento === 2) {
      let labels = [];
      var data = new Date();
      data = new Date(data.setMonth(data.getMonth() + 1));
      for (let i = 0; i < 6; i++) {
        labels.push({ data });
        data = new Date(data.setMonth(data.getMonth() - 1));
      }
      this.setState({ labels });
      return labels;
    } else {
      if (mes !== "-2") {
        //retornar os dias do mes especificado
        let labels = [];
        let inicioMes = new Date(ano, mes, 1);
        let mesData = inicioMes.getMonth();
        let soma = 0;
        while (inicioMes.getMonth() === mesData) {
          let diasMes = new Date(inicioMes.setDate(inicioMes.getDate() + soma));
          if (inicioMes.getMonth() === mesData) {
            labels.push({ data: diasMes });
            if (soma === 0) soma = 1;
          }
        }
        this.setState({ labels });
        return labels;
      }
      //retornar os meses do ano especificado
      let labels = [];
      let inicioAno = new Date(ano, 0, 1);
      let soma = 0;
      for (let i = 0; i < 12; i++) {
        let mesesAno = new Date(
          inicioAno.setMonth(inicioAno.getMonth() + soma)
        );
        labels.push({ data: mesesAno });
        if (!soma) soma = 1;
      }
      this.setState({ labels });
      return labels;
    }
  }
  
  zerarValoresVendas(labels) {
    for (let i = 0; i < labels.length; i++) {
      labels[i].qtdVendas = 0;
      labels[i].precoVendas = 0;
      labels[i].cmarginal = 0;
    }
    return labels;
  }
  
  async getCarteiraClientes() {
    await axios.get(`${REQUEST_URL}clientes/carteira/${this.state.vendedor}/${this.state.considerarInativo}`).then((response) => {
      this.setState({ carteiraClientes: response.data });
    }).catch((error) => {
      console.log(error);
    });
  }

  async getClientesAtivos() {
    let dataInicio = this.getDataInicio(),
      dataFim = this.getDataFim();
    await axios.get(`${REQUEST_URL}clientes/ativos/${dataInicio}/${dataFim}/${this.state.vendedor}/${this.state.considerarInativo}`).then((response) => {
      this.setState({ clientesAtivos: response.data });
    }).catch((error) => {
      console.log(error);
    });
  }
  
  async getClientesInativos() {
    let dataInicio = this.getDataInicio(),
      dataFim = this.getDataFim();
    await axios.get(`${REQUEST_URL}clientes/inativos/${dataInicio}/${dataFim}/${this.state.vendedor}/${this.state.considerarInativo}`).then((response) => {
      this.setState({ clientesInativos: response.data });
    }).catch((error) => {
      console.log(error);
    });
  }

  getTicketMedio() {
    return this.state.totalUltimoAno.valorVendas / this.state.clientesAtivos.length;
  }

  getCarteiraIdeal() {
    return (this.state.totalUltimoAno.metaAnual * this.state.clientesAtivos.length / this.state.totalUltimoAno.valorVendas);
  }

  getVendaVigente() {
    let ops = this.state.ordensProducao;
    let hj = new Date();
    let valor = 0;
    for (let i = 0; i < ops.length; i++) {
      let dt = this.sqlDateToJsDate(ops[i].DtEmissaoOp);
      if (
        dt.getMonth() === hj.getMonth() &&
        dt.getFullYear() === hj.getFullYear()
      )
        valor += ops[i].PrecoTotal;
    }
    return valor;
  }

  getMetaVigente() {
    let metas = this.state.metaVenda;
    let hj = new Date();
    let meta = 0;
    for (let i = 0; i < metas.length; i++) {
      let dt = this.sqlDateToJsDate(metas[i].Periodo);
      if (
        dt.getMonth() === hj.getMonth() &&
        dt.getFullYear() === hj.getFullYear()
      ) {
        meta += metas[i].Meta;
      }
    }
    return meta;
  }

  getVendedores = async () => {
    await axios.get(REQUEST_URL + "vendedores").then((response) => {
      let data = response.data.filter((v) => { return v.IdSaleTeam === 2 });
      this.setState({todosVendedores: data.sort((a, b) => { return ('' + a.Name).localeCompare(b.Name) })});
    }).catch(() => {
      notify.show('Falha na comunicação com o METRICS!',"error");
    })
  }

  login (code, id, event) {
    event.preventDefault();

    if (code === "admin" && id === "admin") {
      this.setUsuarioLogado({codigo: "t63", nome: "Promocional", tipoAcesso: "A", timeRanking: 63});
      this.setState({vendedor: "t63", nomeVendedor: "Promocional", tipoAcesso: "A", timeRanking: 63, login_codigo: "", login_id: ""});
      this.buscarDados("t63");
      return;
    }

    for (let i = 0; i < this.state.todosVendedores.length; i++) {
      let v = this.state.todosVendedores[i];
      if (code == v.Code && v.idActor == id) {
        this.setUsuarioLogado({codigo: code, nome: v.Name, timeRanking: 2, tipoAcesso: "V"});
        this.setState({vendedor: code, nomeVendedor: v.Name, timeRanking: v.IdSaleTeam, tipoAcesso: "V", login_codigo: "", login_id: ""}); 
        this.buscarDados(code);
        return;
      }
    }

    notify.show('A senha inserida está errada!', "error");
  }

  prepararDashboardParaOutraPessoa (vendedor) {
    let timeRanking = vendedor.Code.substring(0, 1) === "t" ? 63 : 2,
      tipoAcesso = vendedor.Code.substring(0, 1) === "t" ? "A" : "V";
    this.setState({nomeVendedor: vendedor.Name, timeRanking, vendedor: vendedor.Code, tipoAcesso}, () => {
      this.buscarDados(vendedor.Code);
    });
  }

  logoff () {
    sessionStorage.removeItem("usuarioLogado");
    this.setState({tipoAcesso: "", nomeVendedor: "", timeRanking: 0, vendedor: ""});
  }

  getUsuarioLogado () {
    return JSON.parse(sessionStorage.getItem("usuarioLogado"));
  }

  setUsuarioLogado (admin) {
    sessionStorage.setItem("usuarioLogado", JSON.stringify(admin));
  }

  _renderOptionsAno(){
    let anoAtual =  new Date().getFullYear(),
      primieroAno = 2017,
      anos = [];

    for (let i = primieroAno; i <= anoAtual; i++) {
      anos.push(i);
    }

    return anos.map((ano) => {      
      return <option key={ano} value={ano}>{ano}</option>
    });
  }

  _renderLogin () {
    return (
      <div>
        <Notifications />
        <div style={{width: "100vw", height: "100vh", backgroundColor: "#2f2f2f", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column"}}>
          <div style={{backgroundColor: "white", width: 350, padding: "40px 40px 60px"}}>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
              <img src="./images/logo-ultrapress.png" alt="Logo Alpha Ultrapress" height="120"/>
              <p style={{fontWeight: 600, margin: "20px 0px 30px"}}>FAÇA O LOGIN PARA CONTINUAR</p>
            </div>
            <form className="form" onSubmit={this.login.bind(this, this.state.login_codigo, this.state.login_id)}>
              <div className="form-group">
                <label>Usuário</label>
                <select className="select form-control input-lg" style={{borderRadius: 0}} onChange={(e) => this.setState({login_codigo: e.target.value})} value={this.state.login_codigo}>
                  <option value="0" default>Selecione</option>
                  <option value="admin" default>Admin</option>
                  {
                    this.state.todosVendedores.length > 0 ?
                      this.state.todosVendedores.map((vendedor) => {
                        return ( 
                          <option key={vendedor.Code} value={vendedor.Code}>{vendedor.Name}</option>
                        )
                      })
                    : null
                  }
                </select>
              </div>
              <div className="form-group">
                <label>Senha</label>
                <input value={this.state.login_id} onChange={(event)=>this.setState({login_id:event.target.value})} type="password" className="form-control"/>
              </div>
              <button type="submit" className="btn btn-block btn-lg" style={{backgroundColor: "red", color: "white", borderRadius: 2, marginTop: 45}}>Entrar</button>
            </form>
          </div>
          <p style={{fontWeight: 600, marginTop: 5}}>Alpha Ultrapress</p>
        </div>
      </div>
    )
  }

  render() {
    if (this.getUsuarioLogado()) {
      return (
        <div>
          <div className="App" style={this.state.loading ? {display: "none"} : {display: "block"}}>
            <nav className="navbar navbar-inverse navbar-fixed-top">
              <div className="container">
                <div className="navbar-header">
                  <button type="button" className="navbar-toggle colapsed" data-toggle="collapse" data-target="#navbarCollapse" aria-expanded="false">
                    <span className="sr-only">Toggle navigation</span>
                    <span className="icon-bar"/>
                    <span className="icon-bar"/>
                    <span className="icon-bar"/>
                  </button>
                  <a className="navbar-brand"><span style={{color: "white"}}>{this.state.nomeVendedor}</span></a>
                </div>
                <div className="collapse navbar-collapse" id="navbarCollapse">
                  {
                    this.state.carregandoMesAno ?
                      <div style={{display: "flex", alignItems: "center", justifyContent: "flex-end", height: 60}}><i style={{marginRight: 5}} className="fa fa-spinner fa-spin"></i> Carregando</div>
                    : <ul className="nav navbar-nav pull-right" style={{ display: "flex", alignItems: "center", justifyContent: "center", height: 60, margin: 0 }}>
                      <li style={{marginRight: 10}}>
                        <select className="select form-control" onChange={this.handleChangeAno} value={this.state.selectAno}>
                          <option value="12" default>Ultimos 12 Meses </option>
                          <option value="6">Ultimos 6 Meses </option>
                          {this._renderOptionsAno()}
                        </select>
                      </li>
                      <li>
                        <select className="select form-control" disabled={this.state.selectAno === "12" || this.state.selectAno === "6" ? true : false}  onChange={this.handleChangeMes} value={this.state.selectMes}>
                          <option value="-2">Todos</option>
                          <option value="0">Janeiro</option>
                          <option value="1">Fevereiro</option>
                          <option value="2">Março</option>
                          <option value="3">Abril</option>
                          <option value="4">Maio</option>
                          <option value="5">Junho</option>
                          <option value="6">Julho</option>
                          <option value="7">Agosto</option>
                          <option value="8">Setembro</option>
                          <option value="9">Outubro</option>
                          <option value="10">Novembro</option>
                          <option value="11">Dezembro</option>
                        </select>
                      </li>
                      <li>
                        <button style={{marginLeft: 10}} className="btn btn-danger" onClick={this.logoff.bind(this)} type="button"> Sair </button>
                      </li>
                    </ul>
                  }
                </div>
              </div>
            </nav>

            <div className="panel panel-default ranking" style={{position:"absolute",left:0,top:400}}>
              <div className="panel-body">
                Considerar cliente inativo com: <button className="btn btn-primary" onClick={this.considerarInativo.bind(this)}>{this.state.considerarInativo} meses</button>
                <br/>
                Clique para alterar
              </div>
            </div>
            
            <div className="container" style={{ marginTop: 125 }}>
              {
                this.state.carregandoMesAno ?
                  <div style={{display: "flex", justifyContent: "center", alignItems: "center", fontSize: 20, backgroundColor: "white", padding: 20, margin: "0px -10px 20px", border: "1px solid #e9ecf2", boxShadow: "0 1px 1px rgba(0,0,0,.05)", borderRadius: 4}}><i style={{marginRight: 5}} className="fa fa-spinner fa-spin"></i> Carregando</div>
                : <React.Fragment>
                  <Ranking
                    meta={this.state.metaVendas}
                    saleTeam={this.state.timeRanking}
                    code={this.state.vendedor}
                    mes={this.state.selectMes}
                    dtInicio={this.getDataInicio()}
                    dtFinal={this.getDataFim()}
                    carregarDashBoardParaOVendedor={(vendedorSelecionado) => this.prepararDashboardParaOutraPessoa.bind(this, vendedorSelecionado)}
                    style={{position: "absolute", right: 0, top: 0, padding: 40}}
                  />
                  <Resumo
                    clientesUltOrc={this.state.clientesUltOrc}
                    //inclusos={this.state.clientesUltOrc.filter((c)=>{ return  new Date(c.InsDt).getFullYear() === new Date().getFullYear() && new Date(c.InsDt).getMonth() === new Date().getMonth()} )}
                    //orcados={this.state.clientesUltOrc.filter((c)=>{ return  new Date(c.InsDt).getFullYear() === new Date().getFullYear() && new Date(c.InsDt).getMonth() === new Date().getMonth() && c.DtUltimoOrc} )}
                    //opgeradas={this.state.clientesUltOrc.filter((c)=>{ return  new Date(c.InsDt).getFullYear() === new Date().getFullYear() && new Date(c.InsDt).getMonth() === new Date().getMonth() && c.DtUltimoOrc && c.InclusaoOp} )}
                    clientesAtivos={this.state.clientesAtivos}
                    clientesInativos={this.state.clientesInativos}
                    ticketMedio={this.getTicketMedio()}
                    carteiraIdeal={this.getCarteiraIdeal()}
                    faturamento={this.state.precoVendas}
                    meta={this.state.metaVendas}
                    vigente={this.getVendaVigente()}
                    metaVigente={this.getMetaVigente()}
                    mes={this.state.selectMes}
                    ano={this.state.selectAno}
                    ativados={this.props.ativados}
                    desativados={this.props.desativados}
                    negociacao={this.props.negociacao}
                    vendedor={this.state.vendedor}
                    dtInicio={this.getDataInicio()}
                    dtFim={this.getDataFim()}
                  />
                  <div className="row">
                    <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4" style={{padding: 5}}>
                      <CarteiraClientes 
                        clientesAtivos={this.state.clientesAtivos}
                        clientesInativos={this.state.clientesInativos}
                        vendedor={this.state.vendedor}
                        labels={this.state.labels}
                        considerarInativoCom={this.state.considerarInativo}
                        dataFim={this.getDataFim()}
                        mes={this.state.selectMes}
                        carteiraClientes={this.state.carteiraClientes}
                      />
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4" style={{padding: 5}}>
                      <FaturamentoEEmNegociacao
                        opsFaturamento={this.state.opsFaturamento}
                        faturamento={this.state.precoVendas}
                        vendedor={this.state.vendedor}
                        ano={this.state.selectAno}
                        labels={this.state.labels} />
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4" style={{padding: 5}}>
                      <TicketMedio anoAtual={this.state.selectAno} vendedor={this.state.vendedor} />
                    </div>
                  </div>
                </React.Fragment>
              }
              <div className="row" style={{ margin: "0px -8px" }}>
                <Faturamento
                  title="FATURAMENTO"
                  data={this.state.dadosPanelFaturamento}
                  carregando={this.state.carregandoPanelFaturamento}/>
                <FunilVendas
                  carteiraTotal={this.state.clientesAtivos.length + this.state.clientesInativos.length}
                  clientesAtivos={this.state.clientesAtivos.length}
                  vendedor={this.state.vendedor}
                  meta12meses={this.state.totalUltimoAno.metaAnual}
                  faturamento12meses={this.state.totalUltimoAno.valorVendas}
                />
                {/*<Vendas title="VENDAS"*/}
                {/*        vendedor={this.state.vendedor}*/}
                {/*        labels={this.state.labels}*/}
                {/*        chartData={this.state.chartDataVendas} ops={this.state.opsFiltradas}/>*/}
                <Orcado labels={this.state.labels} ordensProducao={this.state.ordensProducao} vendedor={this.state.vendedor} />
                <MetaVariavel
                  vendedor={this.state.vendedor}
                  metaAnual={this.state.metaAnual}
                  ano={this.state.selectAno} />
                <ClassificacaoABC
                  vendedor={this.state.vendedor}
                />
              </div>
            </div>
          </div>
          {
            this.state.loading ?
              <div className="Loading" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100vh" }}>
                <img src="loading.svg" alt="loading"/>
                <h2>Preparando dashboard</h2>
              </div>
            : null
          }
        </div>
      )
    } else {
      return this._renderLogin();
    }
  }
}
const mapStateToProps = (state) => {
  return {
    texto: state.texto,
    ativados: state.ativados,
    desativados: state.desativados,
    negociacao: state.negociacao
  };
};
export default connect(mapStateToProps)(App);
