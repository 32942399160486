import React,{Component} from 'react';
import {Bar} from 'react-chartjs-2';
import ReactTable from "react-table";
import 'react-table/react-table.css'
import Orcado from "./Orcado";
const OPTIONS = {
    responsive: true,
    maintainAspectRatio:true,
    tooltips: {
        mode: 'index',
        intersect: true
    },
    scales: {
        yAxes: [{
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-1",
            scaleLabel: {
                     display: true,
                     labelString: 'Quantidade'
                 }
        }, {
            type: "linear",
            display: true,
            position: "right",
            id: "y-axis-2",
            gridLines: {
                drawOnChartArea: false
            },
            scaleLabel: {
                     display: true,
                     labelString: 'Valor Total'
                 }
        }],
        xAxes: [{
                display: true,
                scaleLabel: {
                    display: true,
                    labelString: 'Tempo'
                }
            }],
    }
  }

class Vendas extends Component{
  constructor(props){
    super(props);
    this.state = {
      redraw:false,
      chartData:{},
    }
  }
  componentWillReceiveProps(nextProps){
  //  console.log(this.props.ops);
    if(this.state.chartData){
      this.setState({chartData:nextProps.chartData})
    }
  }
  numberToReal(numero) {
    var NewNumero = numero.toFixed(2).split('.');
    NewNumero[0] =  NewNumero[0].split(/(?=(?:...)*$)/).join('.');
    return NewNumero.join(',');
  }
  getValorTotal(){
    let soma =0;
    this.props.ops.map((op)=>{
      soma+=op.PrecoTotal;
    })
    return soma;
  }
  render(){
    return (
    <div className="panel panel-default col-md-12  ">
      <div className="panel-heading" style={{textAlign:"left"}}>
        {this.props.title}
        <span className="pull-right clickable panel-toggle panel-button-tab-left panel-collapsed"><em className="fa fa-toggle-down"></em></span>
        <button data-toggle="modal" data-target="#modalOrdens"   className="btn btn-default  pull-right">Ordens</button>

      </div>
      <div className="panel-body" style={{display:"none"}}>
        {/*<h3>Grafico</h3>*/}
        {/*<div className="chart">*/}
        {/*  <Bar data={this.state.chartData} options={OPTIONS}/>*/}
        {/*</div>*/}

        {/*<div className="table-responsive">*/}
        {/*  <table className="table table-bordered">*/}
        {/*    <thead>*/}
        {/*    <tr>*/}
        {/*      <th></th>*/}
        {/*      {*/}
        {/*        this.state.chartData.labels ?*/}
        {/*          this.state.chartData.labels.map((l,i)=>(*/}
        {/*            <th key={i}>{l}</th>*/}
        {/*          ))*/}
        {/*          : null*/}
        {/*      }*/}

        {/*    </tr>*/}
        {/*    </thead>*/}
        {/*    <tbody>*/}
        {/*    <tr style={{backgroundColor: "#FF6384"}}>*/}
        {/*      <td>Quantidade</td>*/}
        {/*      {*/}
        {/*        this.state.chartData.labels ?*/}
        {/*          this.state.chartData.labels.map((i,l)=>(*/}
        {/*            <td key={l}>*/}
        {/*              {*/}
        {/*                this.state.chartData.datasets[0].data[l]*/}
        {/*              }*/}
        {/*            </td>*/}
        {/*          ))*/}
        {/*          :null*/}
        {/*      }*/}
        {/*    </tr>*/}
        {/*    <tr style={{backgroundColor: "#FFCD60"}}>*/}
        {/*      <td>Faturamento</td>*/}
        {/*      {*/}
        {/*        this.state.chartData.labels ?*/}
        {/*          this.state.chartData.labels.map((i,l)=>(*/}
        {/*            <td key={l}>*/}
        {/*              R$:*/}
        {/*              {*/}
        {/*                this.state.chartData.datasets[1].data[l] ?*/}
        {/*                  this.numberToReal(Number(this.state.chartData.datasets[1].data[l])):*/}
        {/*                  null*/}

        {/*              }*/}
        {/*            </td>*/}
        {/*          ))*/}
        {/*          :null*/}
        {/*      }*/}
        {/*    </tr>*/}
        {/*    </tbody>*/}
        {/*  </table>*/}
        {/*</div>*/}

        <h3>Tabela</h3>

      </div>


      <div id="modalOrdens" ref="modal" className="modal fade" role="dialog">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal">&times;</button>
              <h4 className="modal-title"> Ordens Produção </h4>
            </div>
            <div className="modal-body" >
              <h3>total: {this.props.ops.length}  faturamento total: {this.getValorTotal().toFixed(2)}</h3>
              <ReactTable
                previousText= {'Anterior'}
                nextText= {'Proximo'}
                noDataText= {'Nada foi encontrado'}
                pageText= {'Pagina'}
                ofText= {'de'}
                rowsText= {'linhas'}
                filterable
                defaultPageSize={20}
                showPageSizeOptions= {false}

                data={this.props.ops}
                columns={[{
                    Header: 'Num Ordem',
                    accessor: 'NumOrdem'
                  }, {
                    Header: 'Num Orc',
                    accessor: 'NumOrcamento'
                  }, {
                    Header: 'PrecoTotal',
                    accessor: 'PrecoTotal',
                    Cell: row => (
                      row.value.toFixed(2)
                    )
                  }, {
                    Header: 'PrecoTotalOrc',
                    accessor: 'PrecoTotalOrc',
                    Cell: row => (
                      row.value.toFixed(2)
                    )
                  }, {
                    Header: 'CodVendedor',
                    accessor: 'CodVendedor'
                  },
                  {
                    Header: 'StatusOP',
                    accessor: 'StatusOP'
                  },
                  {
                    Header: 'DtEmissaoOp',
                    accessor: 'DtEmissaoOp'
                  }
                ]}
              />
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-default" data-dismiss="modal">Fechar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    )
  }
}
export default Vendas;
