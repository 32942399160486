import React, { Component } from 'react';
import {Bar, Line} from 'react-chartjs-2';

const OPTIONS = {
  responsive: true,
  maintainAspectRatio:true,
  tooltips: {
    mode: 'index',
    intersect: true,
    callbacks: {
      label: function(tooltipItem, data) {
        return tooltipItem.yLabel.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
      }
    }
  },

  scales: {
    yAxes: [{
      type: "linear",
      display: true,
      position: "left",
      id: "y-axis-1",
      scaleLabel: {
        display: true,
        labelString: 'Valor'
      }
    }],
    xAxes: [{
      display: true,
      scaleLabel: {
        display: true,
        labelString: 'Meses'
      }
    }],
  }
}

class Faturamento extends Component {
  constructor(props){
    super(props);
    this.state = {
      meses: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', "Out", "Nov", "Dez"],
      dataLineGraph: {
        labels: [],
        datasets: [
          {
            label: '',
            fill: false,
            lineTension: 0.1,
            backgroundColor: 'rgba(255,167,38 ,1)',
            borderColor: 'rgba(245,124,0 ,1)',
            data: []
          },
          {
            label: '',
            fill: false,
            lineTension: 0.1,
            backgroundColor: 'rgba(41,182,246 ,1)',
            borderColor: 'rgba(2,136,209 ,1)',
            data: []
          }
        ]
      }
    };
  }

  async componentWillReceiveProps () {
    if (this.props.data.length > 0 && this.props.carregando === false) {
      console.log(this.props.data);
      this.prepararGrafico();
    }
  }

  async prepararGrafico () {
    let { dataLineGraph, meses } = this.state;

    dataLineGraph.labels = meses;
    
    this.props.data.forEach((item, i) => {
      dataLineGraph.datasets[i].label = item.ano;
      dataLineGraph.datasets[i].data = item.meses;
    });
    
    await this.setState({ dataLineGraph });
  }

  numberToReal (numero) {
    let numeroFormatado = numero.toFixed(2).split('.');
    numeroFormatado[0] = numeroFormatado[0].split(/(?=(?:...)*$)/).join('.');
    return numeroFormatado.join(',');
  }

  render () {
    return (
      <div className="panel panel-default col-md-12">
        <div className="panel-heading" style={{textAlign:"left"}}>
          {this.props.title}
          {
            this.props.carregando ?
              <span className="pull-right panel-button-tab-left"><em className="fa fa-spinner fa-spin"></em></span>
            : <span className="pull-right clickable panel-toggle panel-button-tab-left panel-collapsed"><em className="fa fa-toggle-down"></em></span>
          }
        </div>
        <div className="panel-body" style={{display:"none"}}>
          <h3>Gráfico</h3>
          <Bar data={this.state.dataLineGraph} options={OPTIONS} />
          <h3>Tabela</h3>
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th></th>
                  {
                    this.state.meses.map((i) => (
                      <th key={i}>{i}</th>
                    ))
                  }
                </tr>
              </thead>
              <tbody>
                {
                  this.props.data.map((tr, i) => (
                    <tr key={tr.ano} style={{backgroundColor: this.state.dataLineGraph.datasets[i].backgroundColor}}>
                      <td>{tr.ano}</td>
                      {
                        tr.meses.map((td, j) => (
                          <td key={j}>R${this.numberToReal(td)}</td>
                        ))
                      }
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }
}
export default Faturamento;
