import React from "react";
import axios from "axios";
import { REQUEST_URL } from "../../config/urls";
import Clientes from "../Clientes";

import { Tooltip } from 'react-lightweight-tooltip';
import ReactTable from "react-table";
import 'react-table/react-table.css';

export default class CarteiraClientes extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      clientesAtivos: [],
      clientesInativos: [],
      todosClientesInativos: [],
      todosClientesAtivos: [],
      exibirTodoPeriodo: false,
      clientesSelecionados: [],
      carteiraClientes: [],
      tabelaClientesSelecionados: [],
      todosOsClientes: [],
      renderizarInformacoes: "",
      labels: [],
      abrirClientes: [],
      carregandoTabela: false,
      progressDownload: false
    }
  }

  async componentWillMount () {
    const todosClientesInativos = this.props.carteiraClientes.filter((cliente) => {
      return cliente.orcnovo == null && cliente.StatusCliente !== 'Suspect'
    });
    console.log(todosClientesInativos);
    const todosClientesAtivos = this.props.carteiraClientes.filter((cliente) => { return cliente.orcnovo !== null });
    await this.setState({
      todosClientesInativos: todosClientesInativos,
      todosClientesAtivos: todosClientesAtivos,
      labels: this.props.labels,
      clientesAtivos: this.props.clientesAtivos,
      clientesInativos: this.props.clientesInativos,
      todosOsClientes: [...this.props.clientesAtivos, ...this.props.clientesInativos],
      carteiraClientes: this.props.carteiraClientes
    });
  }

  buscarDados = (clientes) => {
    for (let i=0;i<clientes.length;i++) {
      axios.get(`${REQUEST_URL}orcamentos/${clientes[i].CodCliente}`).then((responseOrc) => {
        clientes[i].orcamentos = responseOrc.data;
        clientes[i].orcamentos.sort(this.comparar);
        clientes[i] = this.definirDataActv(clientes[i]);

        if ((i + 1) === clientes.length) {
          this.setState({ carregandoTabela: false, abrirClientes: clientes });
          this.filtrarClientes(this.state.labels, clientes);
        }
      }).catch((error) => {
        console.log(error);
      });
    }
    
  }

  definirDataActv (cliente) {
    if (!cliente.DtUltimoOrc && cliente.MesesDoUltimoOrc === 9999) {
      //NENHUM ORCAMENTO
      let insDt = new Date(cliente.InsDt);
      cliente.actv = -1; //cliente ativo
      cliente.DtActv = insDt; //Dt ativação = dt inclusao
      cliente.caso = 1
    } else {
      //TEVE Orcamentos
      //MesesDoUltimoOrc maior ou igual
      if (cliente.MesesDoUltimoOrc >=this.props.considerarInativoCom && cliente.MesesDoUltimoOrc !== 9999) {
        //Ultimo superior 12 meses
        let ultimoOrc = new Date(cliente.DtUltimoOrc);
        cliente.actv = 0; //cliente inativo;
        cliente.DtActv = ultimoOrc.setFullYear(ultimoOrc.getFullYear()+1); //Data de desativação = dt ultimo orc + 1 ano
        cliente.caso = 3;
        //console.log("caso 3");
      } else {
        //Ultmo inferior um ANO
        cliente.actv = 1;
        for (let i = 0; i < cliente.orcamentos.length; i++) {//Procurar qual orcamento ativou esse cliente
          if (cliente.orcamentos[i+1]) {
            if (this.dataDiffMeses(new Date(cliente.orcamentos[i].DtInclusao), new Date(cliente.orcamentos[i+1].DtInclusao)) > 12) {// se a diferenca em meses for maior que 12 entao o cliente estava desativado e ativou com o orcamento
              cliente.DtActv = new Date(cliente.orcamentos[i+1].DtInclusao);
              cliente.caso = 4;
            }
          }
        }
        if (!cliente.DtActv) {
          cliente.DtActv = new Date(cliente.InsDt);
          cliente.caso =5
        }
      }
    }

    return cliente;
  }

  dataDiffMeses (d1, d2) {
    let d1Y = d1.getFullYear(),
      d2Y = d2.getFullYear(),
      d1M = d1.getMonth(),
      d2M = d2.getMonth();
    return (d2M + 12 * d2Y) - (d1M + 12 * d1Y);
  }

  comparar (a, b) {
    if (new Date(a.DtInclusao) < new Date(b.DtInclusao)) {
      return -1;
    } else if (new Date(a.DtInclusao) > new Date(b.DtInclusao)) {
      return 1;
    }
    return 0;
  }

  filtrarClientes (labels, clientes) {
    let chaves = labels;
    if (labels.length <= 12) {
      for (let i = 0; i < clientes.length; i++) {
        let dt = new Date(clientes[i].DtActv);
        for (let k = 0; k < chaves.length; k++) {
          if (chaves[k].data.getMonth() === dt.getMonth() && chaves[k].data.getFullYear() === dt.getFullYear()) {
            if (clientes[i].actv && clientes[i].actv != -1) {
              if (!chaves[k].ativos) chaves[k].ativos = [];
              chaves[k].ativos.push(clientes[i]);
            } else if (clientes[i].actv != -1) {
              if (!chaves[k].inativos) chaves[k].inativos = [];
              chaves[k].inativos.push(clientes[i]);
            }
          }
        }
      }
    } else {
      for (let i = 0; i < clientes.length; i++) {
        let dt = new Date(clientes[i].DtActv),
          cliente = clientes[i],
          dtCliente = new Date(cliente.InsDt);
        for (let k = 0; k < chaves.length; k++) {
          if (chaves[k].data.getMonth() === dt.getMonth() && chaves[k].data.getFullYear() === dt.getFullYear() && chaves[k].data.getDate() === dt.getDate()) {
            if (cliente.actv && cliente.actv != -1 && dtCliente.getMonth() === dt.getMonth() && dtCliente.getFullYear() === dt.getFullYear()) {
              if (!chaves[k].ativos) chaves[k].ativos = [];
              chaves[k].ativos.push(cliente);
            } else if (cliente.actv != -1) {
              if (!chaves[k].inativos) chaves[k].inativos = [];
              chaves[k].inativos.push(cliente);
            }
          }
        }
      }
    }
    this.setState({ labels: chaves });
  }

  motivoDoStatus (code) {
    switch (code) {
      case 1:
        return "Incluso e sem orçamentos";
      case 2:
        return "Sem orçamentos";
      case 3:
        return "Ultimo orçamento a mais de 12 meses";
      case 4:
        return "Realizou um orcamento que o ativou";
      case 5:
        return "Ativo desde que foi incluso";
      default:
    }
  }

  handleClickModalClientes = async (clientesSelecionados, renderizar, exibirPorAno = false) => {
    await this.setState({carregandoTabela: true, exibirTodoPeriodo: exibirPorAno});
    let tabelaClientesSelecionados = [];
    if (this.props.mes === "-2" || exibirPorAno) {
      for (let i = 1; i <= 12; i++) {
        tabelaClientesSelecionados.push({dia: i, clientes: []});
      }
      clientesSelecionados.forEach((cliente) => {
        let month = new Date(cliente.InsDt).getMonth();
        tabelaClientesSelecionados[month].clientes.push(cliente);
      });
    } else {
      let ultimoDia = this.props.dataFim.split("-");

      ultimoDia = new Date(ultimoDia[0], ultimoDia[1], 0).getDate();

      for (let i = 1; i <= ultimoDia; i++) {
        tabelaClientesSelecionados.push({dia: i, clientes: []});
      }

      clientesSelecionados.forEach((cliente) => {
        let dt = new Date(cliente.InsDt).getDate();
        tabelaClientesSelecionados[dt - 1].clientes.push(cliente);
      });
    }
    this.setState({
      tabelaClientesSelecionados,
      carregandoTabela: false,
      renderizarInformacoes: renderizar,
      clientesSelecionados
    });
  }

  baixarDados() {
    this.setState({progressDownload: true});
    axios({
      url: REQUEST_URL+'exportar-ativos-inativos',
      method: 'POST',
      data: {
        data: this.state.clientesSelecionados
      },
      responseType: 'blob',
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Clientes.xlsx');
      document.body.appendChild(link);
      link.click();
      this.setState({progressDownload: false});
    }).catch((error) => {
      console.log(error);
      alert('Erro ao baixar a planilha');
    });
  }

  render () {
    let { carteiraClientes } = this.state,
      totalAtivos = carteiraClientes.filter((cliente) => { return cliente.orcnovo !== null}).length,
      totalInativos = carteiraClientes.filter((cliente) => {
        return cliente.orcnovo == null && cliente.StatusCliente !== 'Suspect'
      }).length;

    return (
      <React.Fragment>
        
        <div className="panel panel-default" style={{padding: 10}}>

          <div className="row no-padding">
            <div className="text-muted" style={{marginBottom: 15}}>CARTEIRA DE CLIENTES</div>
            <div style={{display: "flex", justifyContent: "space-around"}}>
              <div style={{display: "flex", flexDirection: "column", justifyContent: "center", cursor: "pointer"}} data-toggle="modal" data-target="#modalCarteira">
                <Tooltip content="Carteira total">
                  <em className="fa fa-xl fa-users color-green"></em>
                </Tooltip>
                <span className="large">{this.props.clientesAtivos.length + this.props.clientesInativos.length} / {this.state.carteiraClientes.length}</span>
                <span>CARTEIRA TOTAL</span>
              </div>
              <div style={{display: "flex", flexDirection: "column", justifyContent: "center", cursor: "pointer"}}  data-toggle="modal" data-target="#modalClientes" onClick={() => this.handleClickModalClientes(this.props.clientesAtivos, "ativos")}>
                <Tooltip content="Clientes ativos">
                  <em className="fa fa-xl fa-user-plus color-teal"></em>
                </Tooltip>
                <span className="large">{this.props.clientesAtivos.length} / {totalAtivos}</span>
                <span>ATIVOS</span>
              </div>
              <div style={{display: "flex", flexDirection: "column", justifyContent: "center", cursor: "pointer"}} data-toggle="modal" data-target="#modalClientes" onClick={() => this.handleClickModalClientes(this.props.clientesInativos, "inativos")}>
                <Tooltip content="Clientes inativos">
                  <em className="fa fa-xl fa-user-times color-red"></em>
                </Tooltip>
                <span className="large">{this.props.clientesInativos.length} / {totalInativos}</span>
                <span>INATIVOS</span>
              </div>
            </div>
          </div>

          <div id="modalClientes" ref="modal" className="modal fade" role="dialog">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal">&times;</button>
                  <h4 className="modal-title"> Clientes selecionados </h4>
                </div>
                <div className="modal-body">
                  <div style={{paddingBottom: '1.5rem'}}>
                    <div style={{display: 'flex', justifyContent: 'center', flexWrap: 'wrap'}} className="btn-group" role="group" >
                      <button className={`btn btn-secondary ${!this.state.exibirTodoPeriodo ? "active":""}`} type="button" onClick={() => this.handleClickModalClientes(
                        this.state.renderizarInformacoes === 'inativos' ?
                          this.props.clientesInativos : this.props.clientesAtivos,
                        this.state.renderizarInformacoes,
                        false)}>
                        Período selecionado
                      </button>

                      <button className={`btn btn-secondary ${this.state.exibirTodoPeriodo ? "active":""}`} type="button" onClick={() => this.handleClickModalClientes(
                        this.state.renderizarInformacoes === 'inativos' ?
                          this.state.todosClientesInativos : this.state.todosClientesAtivos,
                        this.state.renderizarInformacoes,
                        true)}>
                        Todo o período
                      </button>
                    </div>
                  </div>

                  <ReactTable
                    previousText= {'Anterior'}
                    nextText= {'Proximo'}
                    noDataText= {'Nada foi encontrado'}
                    pageText= {'Pagina'}
                    ofText= {'de'}
                    rowsText= {'linhas'}
                    filterable
                    defaultPageSize={10}
                    showPageSizeOptions= {false}
                    data={this.state.clientesSelecionados}
                    columns={[
                      {
                        Header: 'Código',
                        accessor: 'CodCliente'
                      }, {
                        Header: 'Nome',
                        accessor: 'Nome'
                      }, {
                        Header: 'Vendedor',
                        accessor: 'Vendedor'
                      }, {
                        Header: 'Status',
                        accessor: 'StatusCliente'
                      }, {
                        Header: 'DataÚltimoOrc',
                        accessor: 'InsDt',
                        Cell: row => row.value != null ? new Date(row.value).toLocaleDateString() : ''
                      }, {
                        Header: "MesesÚltimoOrc",
                        accessor: 'MesesDoUltimoOrc',
                        Cell: row => (row.value === 9999 ? null : `${row.value} ${row.value == 1 ? "mês" : "meses"}`)
                      }
                    ]}
                  />

                  <div>
                    {
                      this.state.carregandoTabela ?
                        <div style={{ display: "flex", justifyContent: "center", marginTop: 15 }}><i className="fa fa-spinner fa-spin fa-2x"></i></div>
                      : <div className="table-responsive">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th></th>
                              {
                                this.state.tabelaClientesSelecionados.map((item) => (
                                  <th key={item.dia}>{item.dia}</th>
                                ))
                              }
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{this.state.renderizarInformacoes}</td>
                              {
                                this.state.tabelaClientesSelecionados.map((item) => {
                                  if (item.clientes.length > 0) {
                                    return (
                                      <td key={item.dia} 
                                        style={item.clientes.length > 0 ? this.state.renderizarInformacoes === "ativos" ? {cursor: "pointer", backgroundColor: "#2BA651", color: "white"} : {cursor: "pointer", backgroundColor: "#f9243f", color: "white"} : {cursor: "auto"}} 
                                        onClick={() => { this.buscarDados(item.clientes) }} 
                                        data-toggle={item.clientes.length > 0 ? "modal" : ""} 
                                        data-target={item.clientes.length > 0 ? "#modalAbrirClientes" : ""}
                                      >
                                        {item.clientes.length}
                                      </td>
                                    );
                                  } else {
                                    return (
                                      <td key={item.dia} 
                                        style={item.clientes.length > 0 ? this.state.renderizarInformacoes === "ativos" ? {cursor: "pointer", backgroundColor: "#2BA651", color: "white"} : {cursor: "pointer", backgroundColor: "#f9243f", color: "white"} : {cursor: "auto"}} 
                                        data-toggle={item.clientes.length > 0 ? "modal" : ""} 
                                        data-target={item.clientes.length > 0 ? "#modalAbrirClientes" : ""}
                                      >
                                        {item.clientes.length}
                                      </td>
                                    )
                                  }
                                })
                              }
                            </tr>
                          </tbody>
                        </table>
                        <h5 style={{textAlign: "left"}}>* clique nos quadros {this.state.renderizarInformacoes === "ativos" ? "verdes" : "vermelhos"} para mais detalhes</h5>
                      </div>
                    }
                  </div>
                </div>

                <div className="modal-footer">
                  <button onClick={this.baixarDados.bind(this)} className="btn btn-warning">
                    <i className={this.state.progressDownload ? 'fa fa-refresh fa-spin' : 'fa fa-download'} aria-hidden="true" /> Exportar dados
                  </button>

                  <button type="button" className="btn btn-default" data-dismiss="modal">Fechar</button>
                </div>
              </div>
            </div>
          </div>
        
          <div id="modalAbrirClientes" ref="modal" className="modal fade" role="dialog">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal">&times;</button>
                  <h4 className="modal-title"> Clientes </h4>
                </div>
                <div className="modal-body" >
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Cod</th>
                        <th>Nome</th>
                        <th>Vendedor</th>
                        <th>Motivo</th>
                        <th>Último Orc</th>
                        <th>Sem Orc </th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        this.state.abrirClientes.map((c, i)=>(
                          <tr key={i}>
                            <td>{c.CodCliente}</td>
                            <td>{c.Nome}</td>
                            <th>{c.Vendedor}</th>
                            <td>{this.motivoDoStatus(c.caso)}</td>
                            <td>{c.InsDt ? new Date(c.InsDt).toLocaleDateString() : null}</td>
                            <th>{c.MesesDoUltimoOrc === 9999 ? null : `${c.MesesDoUltimoOrc} ${c.MesesDoUltimoOrc == 1 ? "mês" : "meses"}`}</th>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-default" data-dismiss="modal">Fechar</button>
                </div>
              </div>
            </div>
          </div>
        
        </div>
        {/*this.state.todosOsClientes*/}
        <Clientes clientesUltOrc={this.state.carteiraClientes} vendedor={this.props.vendedor}/>

      </React.Fragment>
    );
  }
}
