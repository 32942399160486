import React,{Component} from 'react';
import axios from "axios";
import {REQUEST_URL} from "../../config/urls";

class TicketMedio extends Component {

  constructor(props){
    super(props);
    this.state = {
      anoAtual: null,
      vendedor: null,
      showProgress: false,
      ticketMedMesAtual: 0,
      ticketMedAnoAtual: 0,
      ticketMedAnoPassado: 0
    }
  }

  async componentDidMount () {
    if (
      (
        this.props.vendedor &&
        (!this.state.vendedor || this.state.vendedor !== this.props.vendedor)
      ) || (this.props.anoAtual !== this.state.anoAtual && this.state.vendedor)
    ) {
      await this.setState({
        vendedor: this.props.vendedor,
        anoAtual: this.props.anoAtual
      });
      this.getDadosTicketMedio();
    }
  }

  getDadosTicketMedio() {
    this.setState({showProgress: true});
    axios.get(`${REQUEST_URL}dados-ticket-medio/${this.state.vendedor}/${this.state.anoAtual}`).then((response) => {
      let clientesMesAtual = [], clientesAnoAtual = [], clientesAnoPassado = [];
      let faturamentoMesAtual = 0, faturamentoAnoAtual = 0, faturamentoAnoPassado = 0;

      const dataAtual = new Date();
      response.data.forEach(op => {
        const dataOp = (new Date(op.ApprovalDt));
        // if (dataOp.getFullYear() == dataAtual.getFullYear() && dataOp.getMonth() == dataAtual.getMonth()) {
        //   if (!clientesMesAtual.includes(op.nomecliente)) {
        //     clientesMesAtual.push(op.nomecliente);
        //   }
        //   faturamentoMesAtual += op.PrecoTotal;
        //
        //   // Adicionar ao faturamento anual
        //   if (!clientesAnoAtual.includes(op.nomecliente)) {
        //     clientesAnoAtual.push(op.nomecliente);
        //   }
        //   faturamentoAnoAtual += op.PrecoTotal;
        // } else
        if (dataOp.getFullYear() == this.state.anoAtual) {
          if (!clientesAnoAtual.includes(op.nomecliente)) {
            clientesAnoAtual.push(op.nomecliente);
          }
          faturamentoAnoAtual += op.PrecoTotal;
        } else {
          if (!clientesAnoPassado.includes(op.nomecliente)) {
            clientesAnoPassado.push(op.nomecliente);
          }
          faturamentoAnoPassado += op.PrecoTotal;
        }
      });

      this.setState({
        // ticketMedMesAtual: (faturamentoMesAtual / clientesMesAtual.length),
        ticketMedAnoAtual: (faturamentoAnoAtual / clientesAnoAtual.length),
        ticketMedAnoPassado: (faturamentoAnoPassado / clientesAnoPassado.length)
      })
    }).catch((error) => {
      console.log(error);
    }).finally(() => {
      this.setState({showProgress: false});
    });
  }

  numberToReal(numero) {
    var NewNumero = numero.toFixed(2).split('.');
    NewNumero[0] =  NewNumero[0].split(/(?=(?:...)*$)/).join('.');
    return NewNumero.join(',');
  }

  render() {
    return (
      <div className={"panel panel-default " + (this.state.showProgress ? 'div-desativada' : '')} style={{padding: 10}}>
        <div className="row no-padding">
          <div className="text-muted" style={{marginBottom: 15}}>TICKET MÉDIO</div>
          <div style={{
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            fontSize: '18px'
          }}>
            {/*<div>*/}
            {/*  <span>Mês atual: <strong>R$ {this.numberToReal(this.state.ticketMedMesAtual)}</strong></span>*/}
            {/*</div>*/}
            <div>
              <span>{this.state.anoAtual}: <strong>R$ {this.numberToReal(this.state.ticketMedAnoAtual)}</strong></span>
            </div>
            <div>
              <span>{this.state.anoAtual - 1}: <strong>R$ {this.numberToReal(this.state.ticketMedAnoPassado)}</strong></span>
            </div>
          </div>
        </div>
      </div>
    )
  }

}
export default TicketMedio;
