const initialState = {
     texto: 'Texto inicial!',
     ativados: 0,
     desativados:0,
     negociacao:0
}

export default (state = initialState, action) => {
     switch (action.type) {
          case 'MUDAR_TEXTO':
          console.log("moudou");
              return {...state, texto: action.payload};
          case 'SET_ATIVADOS':
              return {...state, ativados: action.payload};
          case 'SET_DESATIVADOS':
              return {...state, desativados: action.payload};
          case 'SET_NEGOCIACAO':
              return {...state, negociacao: action.payload};
          default:
               return state
     }
}
