import React,{Component} from 'react';

import {Tooltip} from 'react-lightweight-tooltip';
import ReactTable from "react-table";
import 'react-table/react-table.css'
import axios from "axios";
import {REQUEST_URL} from "../config/urls";
import ProspeccaoClientes from "./Dashboard/ProspeccaoClientes";
class Resumo extends Component{
  constructor(props){
    super(props);
    this.state = {
      filtro:"",
      incluso:0,
      orcado:0,
      opGerada:0,
      clientesSelecionados:[],
      dtInicio: null,
      dtFim: null
    }
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps){
    let ano = nextProps.ano;
    let mes = nextProps.mes;
    let uo = nextProps.clientesUltOrc
    //console.log(ano, mes, uo);
    let i = 0;
    let o = 0;
    let f = 0;

    if (nextProps.ano === "12") {
      this.setState({filtro: "Últimos 12 meses"});
    } else if (nextProps.mes !== "") {
      let filtro = new Date(nextProps.ano,nextProps.mes,1);
      this.setState({filtro:filtro.toLocaleDateString("pt-br",{ year: "numeric", month: "long"})});
      i = uo.filter((c)=>{ return  new Date(c.InsDt).getFullYear() == ano && new Date(c.InsDt).getMonth() == mes})
      o = uo.filter((c)=>{ return  new Date(c.InsDt).getFullYear() == ano && new Date(c.InsDt).getMonth() ==  mes && c.DtUltimoOrc} )
      f = uo.filter((c)=>{ return  new Date(c.InsDt).getFullYear() == ano && new Date(c.InsDt).getMonth() == mes && c.DtUltimoOrc && c.InclusaoOp} )
    } else {
      let filtro = new Date(nextProps.ano,nextProps.mes,1);
      this.setState({filtro:filtro.toLocaleDateString("pt-br",{ year: "numeric"})});
      i = uo.filter((c)=>{ return  new Date(c.InsDt).getFullYear() == ano } )
      o = uo.filter((c)=>{ return  new Date(c.InsDt).getFullYear() == ano && c.DtUltimoOrc} )
      f = uo.filter((c)=>{ return  new Date(c.InsDt).getFullYear() == ano && c.DtUltimoOrc && c.InclusaoOp} )
    }
    this.setState({incluso:i, orcado:o, opGerada:f});
  }

  numberToReal(numero) {
    var NewNumero = numero.toFixed(2).split('.');
    NewNumero[0] =  NewNumero[0].split(/(?=(?:...)*$)/).join('.');
    return NewNumero.join(',');
  }

  isNumber(num){
    return (num === "Infinity" || num === "NaN" || num === "undefined" );
  }
  render(){
    return (
      <div className="row">
        {/*// <div className="col-xs-6 col-sm-3 col-md-2 col-lg-2 " style={{padding:5,marginTop:-20}}>*/}
        {/*//   <div className="panel panel-default" style={{height: 150, display: "flex", alignItems: "center", justifyContent: "center",padding:10}}>*/}
        {/*//     <Tooltip content="Carteira ideal para atingir a meta">*/}
        {/*//       <div className="row no-padding">*/}
        {/*//         <em className="fa fa-xl fa-users color-blue"></em>&nbsp;&nbsp;&nbsp;&nbsp;*/}
        {/*//         <em className="fa fa-xl fa-users color-orange"></em>*/}
        {/*/!*        <div className="large">*!/*/}
        {/*//           {*/}
        {/*/!*            this.isNumber(this.props.carteiraIdeal) ?*!/*/}
        {/*/!*              <i className="fa fa-spinner"></i>*!/*/}
        {/*/!*            : this.props.carteiraIdeal.toFixed(0)*!/*/}
        {/*/!*          }*!/*/}
        {/*/!*          {" "}*!/*/}
        {/*/!*          {*!/*/}
        {/*/!*            this.isNumber(this.props.clientesAtivos.length) ?*!/*/}
        {/*//               <i className="fa fa-spinner"></i>*/}
        {/*//             : (this.props.carteiraIdeal - this.props.clientesAtivos.length).toFixed(0)*/}
        {/*//           }*/}
        {/*/!*        </div>*!/*/}
        {/*/!*        <div className="text-muted"> IDEAL / PARA O IDEAL</div>*!/*/}
        {/*/!*      </div>*!/*/}
        {/*/!*    </Tooltip>*!/*/}
        {/*/!*  </div>*!/*/}
        {/*/!*</div>*!/*/}

        <div className="col-xs-6 col-sm-3 col-md-2 col-lg-2" style={{padding:5}}>
          <div className="panel panel-default" style={{height: 150, display: "flex", alignItems: "center", justifyContent: "center",padding:10}}>
            <Tooltip content={"Meta referente ao filtro"}>
              <div className="row no-padding">
                <em className="fa fa-xl fa-bullseye color-orange"></em>
                <div className="large" style={{marginBotton:-10}}>{this.props.ano == 12 || this.props.ano == 6 ? 0: this.numberToReal(this.props.meta)}</div>
                <div style={{marginTop:0}} className="text-muted">META</div>
                <Tooltip content="Para atingir a meta">
                  <label style={this.props.faturamento-this.props.meta > 0 ?{color:"#75B41B"}:{color:"#CE2439"}}>{this.props.ano == 12 || this.props.ano == 6 ? 0: this.numberToReal(this.props.faturamento-this.props.meta) }</label>
                </Tooltip>
              </div>
            </Tooltip>
          </div>
        </div>

        <div className="col-xs-6 col-sm-3 col-md-2 col-lg-2" style={{padding:5}}>
          <div className="panel " style={{height: 150, display: "flex", alignItems: "center", justifyContent: "center",padding:10}}>
            <Tooltip content="Meta mês vigente">
            <div className="row no-padding">
              <em className="fa fa-xl fa-bullseye color-blue"></em>
              <div className="large">{this.numberToReal(this.props.metaVigente)}</div>
              <div style={{marginTop:0}} className="text-muted">META VIGENTE</div>
              <Tooltip content="Para atingir a meta">
                <label style={this.props.vigente-this.props.metaVigente > 0 ?{color:"#75B41B",paddingTop:-10}:{color:"#CE2439",paddingTop:-10}} className="">{this.numberToReal(this.props.vigente-this.props.metaVigente)}</label>
              </Tooltip>
            </div>
            </Tooltip>
          </div>
        </div>

        <ProspeccaoClientes dataInicio={this.props.dtInicio}
                            dataFim={this.props.dtFim}
                            vendedor={this.props.vendedor}/>

        <div id="modalClientesResumo" ref="modal" className="modal fade" role="dialog">
          <div className="modal-dialog" style={{width:"800px"}}>
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">&times;</button>
                <h4 className="modal-title"> Clientes </h4>
              </div>
              <div className="modal-body" >
                <ReactTable
                  previousText= {'Anterior'}
                  nextText= {'Proximo'}
                  noDataText= {'Nada foi encontrado'}
                  pageText= {'Pagina'}
                  ofText= {'de'}
                  rowsText= {'linhas'}
                  filterable
                  defaultPageSize={10}
                  showPageSizeOptions= {false}
                  data={this.state.clientesSelecionados}
                  columns={[
                    {
                      Header: 'Codigo',
                      accessor: 'CodCliente'
                    }, 
                    {
                      Header: 'Nome',
                      accessor: 'Nome'
                    },
                    {
                      Header: 'Vendedor',
                      accessor: 'Vendedor'
                    },
                    {
                      Header: 'Ultimo Orcamento',
                      accessor: 'DtUltimoOrc',
                      Cell: row => {
                        if(new Date(row.value)> new Date('2001','01','01')){
                          return new Date(row.value).toLocaleDateString()
                        }
                      }
                    }, 
                    {
                      Header: 'Status',
                      accessor: 'StatusCliente'
                    },
                    {
                      Header: 'Data de Inclusão',
                      accessor: 'InsDt',
                      Cell: row => (
                        new Date(row.value).toLocaleDateString()
                      )
                    },
                    {
                      Header: 'MesesDoUltimoOrc',
                      accessor: 'MesesDoUltimoOrc',
                      Cell: row => (
                        row.value === 9999 ?null:row.value+" mes(es)"
                      )
                    }
                  ]}
                />

              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-default" data-dismiss="modal">Fechar</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default Resumo;
