import React,{Component} from 'react';
import axios from 'axios';

import { connect } from 'react-redux'
import appActions from '../actions/appActions'
import {REQUEST_URL} from '../config/urls';
const valorFixoMes = 500000;

class OrcsEmElaboracao extends Component{

  constructor(props){
    super(props);
    this.state = {
      orcado: [],
      labels: [],
      vendedor: null,
      ordensProducao: []
    }
  }

  componentDidMount(){
    //console.log(this.props.labels);
  }
  async componentWillReceiveProps(props){
    if (
      this.props.vendedor &&
      (!this.state.vendedor || this.state.vendedor !== this.props.vendedor)
    ) {
      await this.setState({ordensProducao: this.props.ordensProducao, vendedor: this.props.vendedor});
      await axios.get(REQUEST_URL+'orcamentosTodos/'+this.state.vendedor).then((response) => {
        this.setState({orcado:response.data});
        this.filtrarOrcamentos();
      }).catch((error) => {
        console.log(error);
      });
    }
    await this.setState({ordensProducao: this.props.ordensProducao});
    this.filtrarOrcamentos();
  }

  filtrarOrcamentos(){
    let orcamentos = this.state.orcado;
    let labels = this.props.labels;
    for (let k = 0; k < labels.length; k++) {
      labels[k].precoVendas = 0;
    }

    if(labels.length <= 12){
      orcamentos.map((orc)=>{
        labels.map((label)=>{
          if(new Date(orc.DtInclusao).getMonth() === label.data.getMonth() && new Date(orc.DtInclusao).getFullYear() === label.data.getFullYear()){
            if(!label.orcado)label.orcado = orc.SPreco;
            else label.orcado= label.orcado + orc.SPreco;
          }
        })
      });
    }else{
      orcamentos.map((orc)=>{
        labels.map((label)=>{
          if(new Date(orc.DtInclusao).getMonth() === label.data.getMonth() && new Date(orc.DtInclusao).getFullYear() === label.data.getFullYear() && new Date(orc.DtInclusao).getDate() === label.data.getDate()){
            if(!label.orcado)label.orcado = orc.SPreco;
            else label.orcado= label.orcado + orc.SPreco;
          }
        })
      });
    }

    for (let i = 0; i < this.state.ordensProducao.length; i++) {
      let dt = this.sqlDateToJsDate(this.state.ordensProducao[i].DtEmissaoOp);
      for (let k = 0; k < labels.length; k++) {
        if (labels.length <= 12) {
          if (
            labels[k].data.getMonth() === dt.getMonth() &&
            labels[k].data.getFullYear() === dt.getFullYear()
          ) {
            labels[k].precoVendas += this.state.ordensProducao[i].PrecoTotal;
          }
        } else {
          if (
            labels[k].data.getMonth() === dt.getMonth() &&
            labels[k].data.getFullYear() === dt.getFullYear() &&
            labels[k].data.getDate() === dt.getDate()
          ) {
            labels[k].precoVendas += this.state.ordensProducao[i].PrecoTotal;
          }
        }
      }
    }
    this.setState({labels});

  }

  sqlDateToJsDate(stringDt) {
    return new Date(Number(stringDt.substring(0, 4)), Number(stringDt.substring(5, 7)) - 1, Number(stringDt.substring(8, 10)));
  }

  numberToReal(numero) {
    var NewNumero = numero.toFixed(2).split('.');
    NewNumero[0] =  NewNumero[0].split(/(?=(?:...)*$)/).join('.');
    return NewNumero.join(',');
  }

  render(){
    return (
      <div className="panel panel-default col-md-12">
        <div className="panel-heading" style={{textAlign:"left"}}>
          ORÇADO / VENDIDO
          <span className="pull-right clickable panel-toggle panel-button-tab-left panel-collapsed"><em className="fa fa-toggle-down"></em></span>
        </div>
        <div className="panel-body" style={{ maxHeight: "400px",overflowY: "auto",textAlign:"left",display:"none"}}>
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead>
              <tr>
                <th></th>
                {
                  this.state.labels ?
                    this.state.labels.map((l,i) => (
                      <th key={i}>
                        {
                          this.state.labels.length <= 12 ?
                            l.data.toLocaleDateString("pt-br", {year: "numeric", month: "short"})
                            : l.data.toLocaleDateString("pt-br", { day: "numeric"})
                        }
                      </th>
                    ))
                    : null
                }
              </tr>
              </thead>
              <tbody>
              <tr style={{backgroundColor: "#FFCD60"}}>
                <td>Orçado</td>
                {
                  this.state.labels ?
                    this.state.labels.map((l,i) => (
                      <td key={i}>
                        R$:
                        {
                          l.orcado ?
                            this.numberToReal(Number(l.orcado))
                            : this.numberToReal(0)
                        }
                      </td>
                    ))
                    : null
                }
              </tr>
              <tr style={{backgroundColor: "#2ba651"}}>
                <td>Faturado</td>
                {
                  this.state.labels ?
                    this.state.labels.map((l,i) => (
                      <td key={i}>
                        R$:
                        {
                          l.precoVendas ?
                            this.numberToReal(l.precoVendas)
                            : this.numberToReal(0)
                        }
                      </td>
                    ))
                    : null
                }
              </tr>
              <tr style={{backgroundColor: "#5588C7"}}>
                <td>Conversão</td>
                {
                  this.state.labels ?
                    this.state.labels.map((l,i) => (
                      <td key={i}>
                        {
                          l.precoVendas && l.orcado ?
                            ((l.precoVendas*100)/l.orcado).toFixed()
                            : 0
                        }%
                      </td>
                    ))
                    : null
                }
              </tr>
              {/*<tr style={{backgroundColor: "#52A7C5"}}>*/}
              {/*  <td>Custo Fixo</td>*/}
              {/*  {*/}
              {/*    this.state.labels ?*/}
              {/*      this.state.labels.map((l,i) => (*/}
              {/*        <td key={i}>{this.state.labels.length===12 ? "R$:"+this.numberToReal(valorFixoMes) :null}</td>*/}
              {/*      ))*/}
              {/*    : null*/}
              {/*  }*/}
              {/*</tr>*/}
              {/*<tr style={{backgroundColor: "#4FC2BD"}}>*/}
              {/*  <td>C Marginal</td>*/}
              {/*  {*/}
              {/*    this.state.labels ?*/}
              {/*      this.state.labels.map((l,i) => (*/}
              {/*        <td key={i} >*/}
              {/*          R$:*/}
              {/*          {*/}
              {/*            l.cmarginal ?*/}
              {/*              this.numberToReal(l.cmarginal)*/}
              {/*            : this.numberToReal(0)*/}
              {/*          }*/}
              {/*        </td>*/}
              {/*      ))*/}
              {/*    : null*/}
              {/*  }*/}
              {/*</tr>*/}
              {/*<tr style={{backgroundColor: "#4DC098"}}>*/}
              {/*  <td>C Marginal - Custo Fixo</td>*/}
              {/*  {*/}
              {/*    this.state.labels ?*/}
              {/*      this.state.labels.map((l,i) => (*/}
              {/*        <td key={i} style={(l.cmarginal - valorFixoMes) < 0 ? {color:"red"} : {}}>*/}
              {/*          R$:*/}
              {/*          {*/}
              {/*            l.cmarginal ?*/}
              {/*              this.numberToReal(l.cmarginal - valorFixoMes)*/}
              {/*            : this.numberToReal(0)*/}
              {/*          }*/}
              {/*        </td>*/}
              {/*      ))*/}
              {/*    : null*/}
              {/*  }*/}
              {/*</tr>*/}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }


  }
  const mapStateToProps = (state) => {
    return {

    }
  }

  export default connect(mapStateToProps)(OrcsEmElaboracao);
