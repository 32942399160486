import React,{Component} from 'react';
import axios from "axios";
import { REQUEST_URL } from "../../config/urls";

import medalha1 from './medalha1.png'
import medalha2 from './medalha2.png'
import medalha3 from './medalha3.png'

class Ranking extends Component{
  constructor(props){
    super(props);
    this.state = {
      ranking: [],
      rankingBook: [],
      posicao: 0,
      carregando: true,
      buscandoRankingBook: true
    }
  }

  componentWillMount () {
    let dtInicio, dtFinal;

    if (new Date(this.props.dtInicio) > new Date(this.props.dtFinal)) {
      dtInicio = this.props.dtFinal;
      dtFinal  = this.props.dtInicio;
    } else {
      dtInicio = this.props.dtInicio;
      dtFinal  = this.props.dtFinal;
    }

    axios.get(`${REQUEST_URL}ranking_time/${dtInicio}/${dtFinal}/2`).then((response) => {
      this.setState({ranking: response.data, carregando: false});
      this.getPosicao(response.data, this.props.code);
    }).catch((error) => {
      console.log(error);
    });

    axios.get(`${REQUEST_URL}ranking_time/${dtInicio}/${dtFinal}/3`).then((response) => {
      this.setState({rankingBook: response.data, buscandoRankingBook: false});
    }).catch((error) => {
      console.log(error);
    });
  }

  filterSetor(ranking){
    let r = ranking.filter((pos)=>{return pos.IdSaleTeam == this.props.saleTeam})
    this.setState({ranking:r});
    this.getPosicao(r,this.props.code);
  }

  ehAdmin () {
    return sessionStorage.getItem("usuarioLogado") && JSON.parse(sessionStorage.getItem("usuarioLogado")).codigo.substring(0,1) === 't';
  }

  getPosicao(ranking,code){
    for (let i = 0; i < ranking.length; i++) {
      if(ranking[i].Code === code){
        this.setState({posicao:i+1,faturamento:ranking[i].faturamento});
        return;
      }
    }
    this.setState({posicao:-1});
    return
  }

  numberToReal(numero) {
    var NewNumero = numero.toFixed(2).split('.');
    NewNumero[0] =  NewNumero[0].split(/(?=(?:...)*$)/).join('.');
    return NewNumero.join(',');
  }

  getValorTotal(){
    let soma = 0;
    this.state.ranking.map((pos)=>{
      soma+=pos.faturamento;
    })
    return soma;
  }

  getValorTotalBook() {
    let soma = 0;
    this.state.rankingBook.map((pos)=>{
      soma+=pos.faturamento;
    });
    return soma;
  }

  render(){
    return (
      <div>
        <span className="btnOpenRanking" onClick={() => window.openNav()}>Abrir ranking</span>
        <div id="containerSideNav">
          <div id="mySidenav" className="sidenav">
            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
              <span style={{marginLeft: 5, fontSize: 16, fontWeight: 600}}>Ranking Promocional</span>
              <a href="javascript:void(0)" className="closebtn" onClick={() => window.closeNav()}>&times;</a>
            </div>
            {
              this.props.code ?
                this.props.code.substring(0, 1) != 't' ?
                  <div className="panel" style={{padding: 5, margin: 5}}>
                    <div className="row">
                      <div className="col-sm-12">
                        <h3> {this.state.posicao >= 1 ? `Sua posição no ranking é: ${this.state.posicao}º` : "Você não está no ranking. Realize alguma venda para aparecer!"}</h3>
                      </div>
                      <div className="col-sm-12">
                        {this.state.posicao == 1 ? <img style={{width:30,height:50}} src={medalha1} alt="ranking"/> : null}
                        {this.state.posicao == 2 ? <img style={{width:30,height:50}} src={medalha2} alt="ranking"/> : null}
                        {this.state.posicao == 3 ? <img style={{width:30,height:50}} src={medalha3} alt="ranking"/> : null}
                      </div>
                      <div>
                        <h3>{this.state.faturamento >= this.props.meta ? "Parabéns, a sua meta foi alcançada!" : null}</h3>
                      </div>
                    </div>
                  </div>
                  : null
                : null
            }
            <ul className="list-group" style={{padding: 5}}>
              {
                this.ehAdmin() ?
                  <li onClick={this.props.carregarDashBoardParaOVendedor({Code: "t63", Name: "Promocional"})} style={{padding:"0px 20px 0px 20px", cursor: "pointer"}} className={`list-group-item ${this.props.code === "t63" ? "list-group-item-success" : ""} if1`}>
                    <div className="row">
                      <div className="col-sm-12" style={{textAlign: "left"}}>Todos</div>
                      <div className="col-sm-12">
                        <strong className="pull-right">{`R$${this.numberToReal(this.getValorTotal())}`}</strong>
                      </div>
                    </div>
                  </li>
                  : null
              }
              {
                this.state.ranking.map((pos,i) => (
                  <li key={pos.posicao} onClick={this.ehAdmin() ? this.props.carregarDashBoardParaOVendedor(pos) : null} key={pos.posicao} style={{padding: "0px 20px 0px 20px"}} className={`list-group-item ${this.props.code.substring(0,1) !== 't'? pos.Code == this.props.code ? "list-group-item-success" : "if2" : "if1"}`}>
                    <div className="row">
                      <div className="col-sm-12" style={{textAlign: "left"}}>{i + 1} - {pos.Name}</div>
                      <div className="col-sm-12">
                        <strong className="pull-right">{`R$${this.numberToReal(pos.faturamento)}`}</strong>
                      </div>
                    </div>
                  </li>
                ))
              }
            </ul>

            {
              this.ehAdmin() ?
                <div>
                  <br/>
                  <div style={{textAlign: "start"}}>
                    <span style={{marginLeft: 5, fontSize: 16, fontWeight: 600}}>Ranking Book</span>
                    <ul className="list-group" style={{padding: 5}}>
                      <li style={{padding:"0px 20px 0px 20px", cursor: "pointer"}} className={`list-group-item ${this.props.code === "t63" ? "list-group-item-success" : ""} if1`}>
                        <div className="row">
                          <div className="col-sm-12" style={{textAlign: "left"}}>Todos</div>
                          <div className="col-sm-12">
                            <strong className="pull-right">{`R$${this.numberToReal(this.getValorTotalBook())}`}</strong>
                          </div>
                        </div>
                      </li>
                      {
                        this.state.rankingBook.map((pos,i) => (
                          <li key={pos.posicao} style={{padding: "0px 20px 0px 20px"}} className={`list-group-item ${this.props.code.substring(0,1) !== 't'? pos.Code == this.props.code ? "list-group-item-success" : "if2" : "if1"}`}>
                            <div className="row">
                              <div className="col-sm-12" style={{textAlign: "left"}}>{i + 1} - {pos.Name}</div>
                              <div className="col-sm-12">
                                <strong className="pull-right">{`R$${this.numberToReal(pos.faturamento)}`}</strong>
                              </div>
                            </div>
                          </li>
                        ))
                      }
                    </ul>
                  </div>
                </div>
                :
                null
            }
          </div>
        </div>
        {
          this.props.code ?
            this.props.code.substring(0,1) != 't' ?
              <div className="panel ranking" style={{position: "absolute", left: 0, top: 100, padding: 30}} >
                <div className="row">
                  <div className="col-sm-12">
                    <h3>{this.state.posicao >= 1 ? `Sua posição no ranking é: ${this.state.posicao}º` : "Você não está no ranking. Realize alguma venda para aparecer!"}</h3>
                  </div>
                  <div className="col-sm-12">
                    {this.state.posicao == 1 ? <img style={{width: 30, height: 50}} src={medalha1} alt="ranking"/> : null}
                    {this.state.posicao == 2 ? <img style={{width: 30, height: 50}} src={medalha2} alt="ranking"/> : null}
                    {this.state.posicao == 3 ? <img style={{width: 30, height: 50}} src={medalha3} alt="ranking"/> : null}
                  </div>
                  <div>
                    <h3>{this.state.faturamento >= this.props.meta ? "Parabéns, a sua meta foi alcançada!" : null}</h3>
                  </div>
                </div>
              </div>
              : null
            : null
        }
        <div style={{textAlign: "left", position: "absolute", right: 0, top: 100}} className="ranking">
          <div className="panel">
            <div className="panel-heading">Ranking Promocional</div>
            <div className="panel-body">
              {
                this.state.carregando ?
                  <div style={{display: "flex", justifyContent: "center", marginTop: -25}}><i className="fa fa-spinner fa-spin fa-2x"/></div>
                  :
                  <ul className="list-group ">
                    {
                      this.ehAdmin() ?
                        <li onClick={this.props.carregarDashBoardParaOVendedor({Code: "t63", Name: "Promocional"})} style={{padding:"0px 20px 0px 20px", cursor: "pointer"}} className={`list-group-item ${this.props.code === "t63" ? "list-group-item-success" : ""} if1`}>
                          <div className="row">
                            <div className="col-sm-12" style={{textAlign: "left"}}>Todos</div>
                            <div className="col-sm-12">
                              <strong className="pull-right">{`R$${this.numberToReal(this.getValorTotal())}`}</strong>
                            </div>
                          </div>
                        </li>
                        : null
                    }
                    {
                      this.state.ranking.map((pos, i)=>(
                        <li onClick={this.ehAdmin() ? this.props.carregarDashBoardParaOVendedor(pos) : null} key={pos.posicao} style={this.ehAdmin() ? {padding:"0px 20px", cursor: "pointer"} : {padding: "0px 20px"}} className={`list-group-item ${this.props.code.substring(0,1) !== 't' ? pos.Code == this.props.code ? "list-group-item-success" : "if2" : "if1"}`}>
                          <div className="row">
                            <div className="col-sm-12" style={{textAlign: "left"}}>{i + 1} - {pos.Name}</div>
                            <div className="col-sm-12">
                              <strong className="pull-right">{`R$${this.numberToReal(pos.faturamento)}`}</strong>
                            </div>
                          </div>
                        </li>
                      ))
                    }
                  </ul>
              }
            </div>
          </div>

          {
            this.ehAdmin() ?
              <div className="panel">
                <div className="panel-heading">Ranking Book</div>
                <div className="panel-body">
                  {
                    this.state.buscandoRankingBook ?
                      <div style={{display: "flex", justifyContent: "center", marginTop: -25}}><i
                        className="fa fa-spinner fa-spin fa-2x"/></div>
                      :
                      <ul className="list-group ">
                        <li style={{padding:"0px 20px 0px 20px"}} className={`list-group-item`}>
                          <div className="row">
                            <div className="col-sm-12" style={{textAlign: "left"}}>Total</div>
                            <div className="col-sm-12">
                              <strong className="pull-right">{`R$${this.numberToReal(this.getValorTotalBook())}`}</strong>
                            </div>
                          </div>
                        </li>
                        {
                          this.state.rankingBook.map((pos, i) => (
                            <li key={pos.posicao}
                                style={{padding: "0px 20px"}}
                                className={'list-group-item'}>
                              <div className="row">
                                <div className="col-sm-12" style={{textAlign: "left"}}>{i + 1} - {pos.Name}</div>
                                <div className="col-sm-12">
                                  <strong
                                    className="pull-right">{`R$${this.numberToReal(pos.faturamento)}`}</strong>
                                </div>
                              </div>
                            </li>
                          ))
                        }
                      </ul>
                  }
                </div>
              </div>
              :
              null
          }
        </div>
      </div>
    )
  }
}
export default Ranking;
