import React,{Component} from 'react';
import {Doughnut} from "react-chartjs-2";
import axios from "axios";
import {REQUEST_URL} from "../../config/urls";
import ReactTable from "react-table";

class ClassificacaoABC extends Component {

  constructor(props){
    super(props);
    this.state = {
      dados: [],
      opcoesGrafico: {},
      dadosGrafico: [],
      dadosTabela: [],
      tipoCliente: '',
      vendedor: null,
      periodo: 3,
      showProgress: false,
      exibirModal: false
    }
  }

  componentDidMount() {
    const self = this;
    const OPTIONS = {
      responsive: true,
      maintainAspectRatio: true,
      tooltips: {
        mode: 'index',
        intersect: true,
        callbacks: {
          label: function (tooltipItem, data) {
            const indice = tooltipItem.index;

            const dados = self.state.dados;
            let numClientesA = parseInt((dados.length * 0.2).toString(), 10);
            let numClientesB = parseInt((dados.length * 0.50).toString(), 10) - numClientesA;
            let numClientesC = dados.length - (numClientesA + numClientesB);
            let numClientes = 0;

            if (indice === 0) {
              numClientes = numClientesA;
            } else if (indice === 1) {
              numClientes = numClientesB;
            } else if (indice === 2) {
              numClientes = numClientesC;
            }

            return [
              data.labels[indice] +': '+
              data.datasets[0].data[indice].toLocaleString('pt-br', {style: 'currency', currency: 'BRL'}),
              'Número de clientes: ' + parseInt(numClientes.toString(), 10)
            ];
          }
        }
      },
      onClick: (evt, item) => {
        const clientes = [];

        let tipoCliente = '';
        if (item[0]._index === 0) {
          tipoCliente = 'Clientes A';
        } else if (item[0]._index === 1) {
          tipoCliente = 'Clientes B';
        } else if (item[0]._index === 2) {
          tipoCliente = 'Clientes C';
        }

        const dados = this.state.dados;
        let numClientesA = dados.length * 0.2;
        let numClientesB = dados.length * 0.50;
        for (let i = 0; i < dados.length; i++) {
          if ((i + 1) <= numClientesA && item[0]._index === 0) {
            clientes.push(dados[i]);
          } else if ((i + 1) > numClientesA && (i + 1) <= numClientesB && item[0]._index === 1) {
            clientes.push(dados[i]);
          } else if ((i + 1) > numClientesB && item[0]._index === 2) {
            clientes.push(dados[i]);
          }
        }

        this.setState({dadosTabela: clientes, tipoCliente: tipoCliente})
        window.abrirModalClientesABC();
      }
    };
    this.setState({opcoesGrafico: OPTIONS});
  }

  async componentWillReceiveProps(nextProps) {
    if (this.props.vendedor &&
      (
        (!this.state.vendedor || this.state.vendedor !== this.props.vendedor)
      )
    ) {
      await this.setState({
        vendedor: this.props.vendedor
      });
      this.carregarGrafico(this.state.periodo);
    }
  }

  carregarGrafico(periodo) {
    this.setState({periodo: periodo});
    const self = this;
    this.setState({showProgress: true});
    axios.get(REQUEST_URL + `classificacaoabc/${periodo}/${this.state.vendedor}`)
      .then(async function(response) {
        const dados = response.data;

        let somaClientesA = 0, somaClientesB = 0, somaClientesC = 0;
        let numClientesA = dados.length * 0.2;
        let numClientesB = dados.length * 0.50;

        for (let i = 0; i < dados.length; i++) {
          if ((i + 1) <= numClientesA) {
            somaClientesA += dados[i].totalvenda;
          } else if ((i + 1) <= numClientesB) {
            somaClientesB += dados[i].totalvenda;
          } else {
            somaClientesC += dados[i].totalvenda;
          }
        }

        const data = {
          labels: [
            'Clientes A',
            'Clientes B',
            'Clientes C'
          ],
          datasets: [{
            data: [somaClientesA, somaClientesB, somaClientesC],
            backgroundColor: [
              '#1EBFAE',
              '#FFCE56',
              '#90A4AE'
            ],
            hoverBackgroundColor: [
              '#1EBFAE',
              '#FFCE56',
              '#90A4AE'
            ]
          }]
        };

        self.setState({dadosGrafico: data, dados: dados});
      })
      .catch(function(error) {
        console.log(error);
      })
      .finally(() => {
      this.setState({showProgress: false});
    });
  }

  render(){
    return (
      <div className="panel panel-default col-md-12  ">

        <div className="panel-heading" style={{textAlign:"left", display: "flex", alignItems: "center"}}>
          CLASSIFICAÇÃO DE CLIENTES ABC
          <span style={{flex: '1 1 0%', boxSizing: 'border-box'}}></span>
          <span className="pull-right clickable panel-toggle panel-button-tab-left panel-collapsed"><em className="fa fa-toggle-down"></em></span>
          {
            this.state.showProgress ?
              <div><i style={{marginLeft: '15px'}} className="fa fa-spinner fa-spin"></i></div> : ''
          }
        </div>

        <div style={{display:"none"}} className={"panel-body " + (this.state.showProgress ? 'div-desativada' : '')}>
          <div style={{paddingBottom: '1.5rem', paddingTop: '1.5rem'}}>
            <div style={{display: 'flex', justifyContent: 'center', flexWrap: 'wrap'}} className="btn-group" role="group" >
              <button type="button" onClick={this.carregarGrafico.bind(this, 3)} className={`btn btn-secondary ${this.state.periodo === 3 ? "active":""}`}>3 meses</button>
              <button type="button" onClick={this.carregarGrafico.bind(this, 6)} className={`btn btn-secondary ${this.state.periodo === 6 ? "active":""}`}>6 meses</button>
              <button type="button" onClick={this.carregarGrafico.bind(this, 12)} className={`btn btn-secondary ${this.state.periodo === 12 ? "active":""}`}>12 meses</button>
              <button type="button" onClick={this.carregarGrafico.bind(this, 24)} className={`btn btn-secondary ${this.state.periodo === 24 ? "active":""}`}>24 meses</button>
            </div>
          </div>

          <div style={{display: "flex", justifyContent: "center"}} >
            <div style={{width: "50%"}}>
              <Doughnut data={this.state.dadosGrafico} options={this.state.opcoesGrafico} />
            </div>
          </div>
        </div>

        <div id="modalClientesABC" ref="modal" className="modal fade" role="dialog">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">&times;</button>
                <h4 className="modal-title"> {this.state.tipoCliente} </h4>
              </div>
              <div className="modal-body">
                <ReactTable
                  previousText= {'Anterior'}
                  nextText= {'Proximo'}
                  noDataText= {'Nada foi encontrado'}
                  pageText= {'Pagina'}
                  ofText= {'de'}
                  rowsText= {'linhas'}
                  filterable
                  defaultPageSize={10}
                  showPageSizeOptions= {false}
                  data={this.state.dadosTabela}
                  columns={[
                    {
                      Header: 'CodCliente',
                      accessor: 'codcli'
                    }, {
                      Header: 'Cliente',
                      accessor: 'cliente'
                    }, {
                      Header: 'Total',
                      accessor: 'totalvenda',
                      Cell: row => (row.value.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'}))
                    }
                  ]}
                />
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-default" data-dismiss="modal">Fechar</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default ClassificacaoABC;
