import React from "react";
import axios from "axios";
import { Tooltip } from 'react-lightweight-tooltip';
import { REQUEST_URL } from '../../config/urls';
import ReactTable from "react-table";

import 'react-table/react-table.css'

export default class FaturamentoEEmNegociacao extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      valorTotal: 0,
      vendedor: null,
      valorEmNegociacao: 0,
      orcEmNegociacao: [],
      orcamentosFiltrados: []
    }
  }

  async componentDidMount () {
    axios.get(`${REQUEST_URL}orcamentos200/${this.props.vendedor}`).then((response) => {
      this.filtrarOrcamentos(this.props.labels, response.data);
    }).catch((error) => {
      console.log(error);
    });

    if (
      this.props.vendedor &&
      (!this.state.vendedor || this.state.vendedor !== this.props.vendedor)
    ) {
      await this.setState({
        vendedor: this.props.vendedor,
        valorEmNegociacao: 0
      });
      this.getValorEmNegociacao();
    }
  }

  getValorEmNegociacao() {
    axios.get(`${REQUEST_URL}em-negociacao/${this.props.vendedor}`).then((response) => {
      this.setState({valorEmNegociacao: response.data[0].valorEmNegociacao})
    }).catch((error) => {
      console.log(error);
    });
  }

  getOrcEmNegociacao() {
    axios.get(`${REQUEST_URL}orc-em-negociacao/${this.props.vendedor}`).then((response) => {
      this.setState({orcEmNegociacao: response.data})
    }).catch((error) => {
      console.log(error);
    });
  }

  filtrarOrcamentos (labels, orcamentos) {
    let orcamentosFiltrados = [];
    if (labels.length === 12) {
      if (labels[0].data.getFullYear() !== labels[11].data.getFullYear()) {
        for (let i = 0; i < orcamentos.length; i++) {
          if((new Date().setFullYear(new Date().getFullYear()-1) < new Date(orcamentos[i].DtInclusao))) {
            orcamentosFiltrados.push(orcamentos[i]);
          }
        }
      } else {
        for (let i = 0; i < orcamentos.length; i++) {
          if (labels[0].data.getFullYear() === new Date(orcamentos[i].DtInclusao).getFullYear()) {
            orcamentosFiltrados.push(orcamentos[i]);
          }
        }
      }
    } else if (labels.length === 6) {
      for (let i = 0; i < orcamentos.length; i++) {
        if ((new Date().setMonth(new Date().getMonth() - 6) < new Date(orcamentos[i].DtInclusao) && new Date(orcamentos[i].DtInclusao) < new Date())) {
          orcamentosFiltrados.push(orcamentos[i]);
        }
      }
    } else {
      for (let i = 0; i < orcamentos.length; i++) {
        if (labels[0].data.getFullYear() === new Date(orcamentos[i].DtInclusao).getFullYear() && labels[0].data.getMonth() === new Date(orcamentos[i].DtInclusao).getMonth()) {
          orcamentosFiltrados.push(orcamentos[i]);
        }
      }
    }
    this.setState({valorTotal: this.getValorTotal(orcamentosFiltrados), orcamentosFiltrados});
  }

  getValorTotal (orcamentos200) {
    let total = 0;
    orcamentos200.forEach((orc) => {
      total += orc.SPreco;
    });
    return total;
  }

  numberToReal (numero) {
    let NewNumero = numero.toFixed(2).split('.');
    NewNumero[0] = NewNumero[0].split(/(?=(?:...)*$)/).join('.');
    return NewNumero.join(',');
  }

  render () {
    return (
      <div className="panel panel-default" style={{padding: 10}}>
        <div className="row no-padding">
          <div className="text-muted" style={{marginBottom: 15}}>NEGOCIAÇÃO EM ANDAMENTO / FATURADO</div>
          <div style={{display: "flex", justifyContent: "space-around"}}>
            <div style={{display: "flex", flexDirection: "column", justifyContent: "center", cursor: "pointer"}}
                 onClick={this.getOrcEmNegociacao.bind(this)}
                 data-toggle="modal" data-target="#modalNegociacao">
              <Tooltip content="Valor sendo negociado no periodo especificado">
                <em className="fa fa-xl fa-handshake-o color-green"></em>
              </Tooltip>
              <span className="large">R$ {this.numberToReal(this.state.valorEmNegociacao)}</span>
              <span>EM NEGOCIAÇÃO</span>
            </div>
            <div style={{display: "flex", flexDirection: "column", justifyContent: "center", cursor: "pointer"}} data-toggle="modal" data-target="#modalFaturamento">
              <Tooltip content="Faturamento referente ao filtro">
                <em className="fa fa-xl fa-money color-blue"></em>
              </Tooltip>
              <span className="large">{this.numberToReal(this.props.faturamento)}</span>
              <span>FATURAMENTO</span>
            </div>
          </div>
        </div>

        <div id="modalNegociacao" ref="modal" className="modal fade" role="dialog">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">&times;</button>
                <h4 className="modal-title"> Em negociação </h4>
              </div>
              <div className="modal-body">
                <ReactTable
                  previousText= {'Anterior'}
                  nextText= {'Proximo'}
                  noDataText= {'Nada foi encontrado'}
                  pageText= {'Pagina'}
                  ofText= {'de'}
                  rowsText= {'linhas'}
                  filterable
                  defaultPageSize={15}
                  showPageSizeOptions= {false}
                  data={this.state.orcEmNegociacao}
                  columns={[
                    {
                      Header: 'Orçamento',
                      accessor: 'NumOrcamento'
                    }, {
                      Header: 'Título',
                      accessor: 'Titulo'
                    }, {
                      Header: 'NomeCliente',
                      accessor: 'NomeCliente'
                    }, {
                      Header: 'Preço',
                      accessor: 'SPreco',
                      Cell: row => (row.value.toFixed(2))
                    }, {
                      Header: 'Data inclusão',
                      accessor: 'DtInclusao',
                      Cell: row => (new Date(row.value).toLocaleDateString())
                    }
                  ]}
                />
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-default" data-dismiss="modal">Fechar</button>
              </div>
            </div>
          </div>
        </div>

        <div id="modalFaturamento" ref="modal" className="modal fade" role="dialog">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">&times;</button>
                <h4 className="modal-title"> Faturamento </h4>
              </div>
              <div className="modal-body">
                <ReactTable
                  previousText= {'Anterior'}
                  nextText= {'Proximo'}
                  noDataText= {'Nada foi encontrado'}
                  pageText= {'Pagina'}
                  ofText= {'de'}
                  rowsText= {'linhas'}
                  filterable
                  defaultPageSize={15}
                  showPageSizeOptions= {false}
                  data={this.props.opsFaturamento}
                  columns={[
                    {
                      Header: 'Orçamento',
                      accessor: 'NumOrcamento'
                    }, {
                      Header: 'Título',
                      accessor: 'DescrOrc'
                    }, {
                      Header: 'NomeCliente',
                      accessor: 'nomecliente'
                    }, {
                      Header: 'Preço',
                      accessor: 'SPreco',
                      Cell: row => (row.value.toFixed(2))
                    }, {
                      Header: 'Data OP',
                      accessor: 'DtEmissaoOp',
                      Cell: row => (new Date(row.value).toLocaleDateString())
                    }
                  ]}
                />
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-default" data-dismiss="modal">Fechar</button>
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
}
