import React from "react";
import axios from "axios";
import { REQUEST_URL } from "../../config/urls";

import { Tooltip } from 'react-lightweight-tooltip';
import ReactTable from "react-table";
import 'react-table/react-table.css';

export default class ProspeccaoClientes extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      incluidos: [],
      orcados: [],
      orcadosVelhos: [],
      fecharamVelhos: [],
      fecharam: [],
      clientesSelecionados: []
    }
  }

  async componentDidMount () {
    let dataInicio = this.props.dataInicio,
      dtFim = this.props.dataFim,
      vendedor = this.props.vendedor === "t63" ? 0 : this.props.vendedor;

    await this.getIncluidos(dataInicio, dtFim, vendedor);
    await this.getOrcados(dataInicio, dtFim, vendedor);
    await this.getFecharam(dataInicio, dtFim, vendedor);
  }

  getIncluidos (dataInicio, dtFim, vendedor) {
    axios.get(`${REQUEST_URL}clientes/incluidos/${dataInicio}/${dtFim}/${vendedor}`).then((response) => {
      this.setState({incluidos: response.data});
    }).catch((error) => {
      console.log(error);
    });
  }

  getOrcados (dataInicio, dtFim, vendedor) {
    axios.get(`${REQUEST_URL}clientes/orcados/${dataInicio}/${dtFim}/${vendedor}`).then((response) => {
      const novos = response.data.filter((dados) => {
        return dados.cliente_novo === 'novo';
      });

      const velhos = response.data.filter((dados) => {
        return dados.cliente_novo === 'velho';
      });

      this.setState({orcados: novos, orcadosVelhos: velhos});
    }).catch((error) => {
      console.log(error);
    });
  }

  getFecharam (dataInicio, dtFim, vendedor) {
    axios.get(`${REQUEST_URL}clientes/fecharam/${dataInicio}/${dtFim}/${vendedor}`).then((response) => {
      const novos = response.data.filter((dados) => {
        return dados.nunca_fez_op === 'novo';
      });

      const velhos = response.data.filter((dados) => {
        return dados.nunca_fez_op === 'velho';
      });

      this.setState({fecharam: novos, fecharamVelhos: velhos});
    }).catch((error) => {
      console.log(error);
    });
  }

  render () {
    return (
      <div>
        <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4" style={{padding: 5}}>
          <div className="panel panel-default" style={{padding: 10, minHeight: 150}}>
            <div className="row no-padding">
              <div className="text-muted" style={{marginBottom: 15}}>CLIENTES NOVOS</div>
              <div style={{display: "flex", justifyContent: "space-around"}}>
                <div style={{display: "flex", flexDirection: "column", justifyContent: "center", cursor: "pointer"}} data-toggle="modal" data-target="#modalProspeccaoClientes" onClick={() => {this.setState({clientesSelecionados: this.state.incluidos})}}>
                  <Tooltip content="Clientes inclusos neste período">
                    <em className="fa fa-xl fa-user color-orange"></em>
                  </Tooltip>
                  <span className="large">{this.state.incluidos.length}</span>
                  <span>INCLUÍDOS</span>
                </div>
                <div style={{display: "flex", flexDirection: "column", justifyContent: "center", cursor: "pointer"}} data-toggle="modal" data-target="#modalProspeccaoClientes" onClick={() => {this.setState({clientesSelecionados: this.state.orcados})}}>
                  <Tooltip content="Clientes com orçamentos feitos neste período">
                    <em className="fa fa-xl fa-user color-blue"></em>
                  </Tooltip>
                  <span className="large">{this.state.orcados.length}</span>
                  <span>ORÇADOS</span>
                </div>
                <div style={{display: "flex", flexDirection: "column", justifyContent: "center", cursor: "pointer"}} data-toggle="modal" data-target="#modalProspeccaoClientes" onClick={() => {this.setState({clientesSelecionados: this.state.fecharam})}}>
                  <Tooltip content="Clientes que fecharam com a Alpha neste período">
                    <em className="fa fa-xl fa-user color-teal"></em>
                  </Tooltip>
                  <span className="large">{this.state.fecharam.length}</span>
                  <span>FECHARAM</span>
                </div>
              </div>
            </div>

            <div id="modalProspeccaoClientes" ref="modal" className="modal fade" role="dialog">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                    <h4 className="modal-title"> Clientes selecionados </h4>
                  </div>
                  <div className="modal-body">
                    <ReactTable
                      previousText= {'Anterior'}
                      nextText= {'Proximo'}
                      noDataText= {'Nada foi encontrado'}
                      pageText= {'Pagina'}
                      ofText= {'de'}
                      rowsText= {'linhas'}
                      filterable
                      defaultPageSize={10}
                      showPageSizeOptions= {false}
                      data={this.state.clientesSelecionados}
                      columns={[{
                        Header: 'Código',
                        accessor: 'CodCliente'
                      }, {
                        Header: 'Nome',
                        accessor: 'Nome'
                      }, {
                        Header: 'Vendedor',
                        accessor: 'Vendedor'
                      }, {
                        Header: 'Data inclusão',
                        accessor: 'data',
                        Cell: row => new Date(row.value).toLocaleDateString()
                      }
                      ]}
                    />

                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-default" data-dismiss="modal">Fechar</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4" style={{padding: 5}}>
          <div className="panel panel-default" style={{padding: 10, minHeight: 150}}>
            <div className="row no-padding">
              <div className="text-muted" style={{marginBottom: 15}}>CLIENTES RECUPERADOS</div>
              <div style={{display: "flex", justifyContent: "space-around"}}>
                <div style={{display: "flex", flexDirection: "column", justifyContent: "center", cursor: "pointer"}} data-toggle="modal" data-target="#modalProspeccaoClientes" onClick={() => {this.setState({clientesSelecionados: this.state.orcadosVelhos})}}>
                  <Tooltip content="Clientes com orçamentos feitos neste período">
                    <em className="fa fa-xl fa-user color-blue"></em>
                  </Tooltip>
                  <span className="large">{this.state.orcadosVelhos.length}</span>
                  <span>ORÇADOS</span>
                </div>
                <div style={{display: "flex", flexDirection: "column", justifyContent: "center", cursor: "pointer"}} data-toggle="modal" data-target="#modalProspeccaoClientes" onClick={() => {this.setState({clientesSelecionados: this.state.fecharamVelhos})}}>
                  <Tooltip content="Clientes que fecharam com a Alpha neste período">
                    <em className="fa fa-xl fa-user color-teal"></em>
                  </Tooltip>
                  <span className="large">{this.state.fecharamVelhos.length}</span>
                  <span>FECHARAM</span>
                </div>
              </div>
            </div>

            <div id="modalProspeccaoClientes" ref="modal" className="modal fade" role="dialog">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                    <h4 className="modal-title"> Clientes selecionados </h4>
                  </div>
                  <div className="modal-body">
                    <ReactTable
                      previousText= {'Anterior'}
                      nextText= {'Proximo'}
                      noDataText= {'Nada foi encontrado'}
                      pageText= {'Pagina'}
                      ofText= {'de'}
                      rowsText= {'linhas'}
                      filterable
                      defaultPageSize={10}
                      showPageSizeOptions= {false}
                      data={this.state.clientesSelecionados}
                      columns={[{
                        Header: 'Código',
                        accessor: 'CodCliente'
                      }, {
                        Header: 'Nome',
                        accessor: 'Nome'
                      }, {
                        Header: 'Vendedor',
                        accessor: 'Vendedor'
                      }, {
                        Header: 'Data inclusão',
                        accessor: 'data',
                        Cell: row => new Date(row.value).toLocaleDateString()
                      }
                      ]}
                    />

                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-default" data-dismiss="modal">Fechar</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
  }
}
