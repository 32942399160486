import React,{Component} from 'react';
import {Line} from 'react-chartjs-2';
import axios from "axios";
import {REQUEST_URL} from "../config/urls";

const OPTIONS = {
  responsive: true,
  maintainAspectRatio: true,
  tooltips: {
    mode: 'index',
    intersect: true,
    callbacks: {
      label: function (tooltipItem, data) {
        return tooltipItem.yLabel.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'});
      }
    }
  },
};

class MetaVariavel extends Component{

  constructor(props){
    super(props);
    this.state = {
      redraw:false,
      chartData:{},
      vendedor: null,
      ano: null,
      dadosVenda: null,
      metaAnual: null,
      showProgress: false
    }
  }

  async componentWillReceiveProps(nextProps) {
    if (this.props.vendedor && this.props.ano &&
      (
        (!this.state.vendedor || this.state.vendedor !== this.props.vendedor) ||
        (!this.state.ano || this.state.ano !== this.props.ano) ||
        (!this.state.metaAnual || this.state.metaAnual !== this.props.metaAnual)
      )
    ) {
      await this.setState({
        vendedor: this.props.vendedor,
        ano: this.props.ano,
        metaAnual: this.props.metaAnual
      });
      await this.getDados();
    }
  }

  numberToReal(numero) {
    var NewNumero = numero.toFixed(2).split('.');
    NewNumero[0] =  NewNumero[0].split(/(?=(?:...)*$)/).join('.');
    return NewNumero.join(',');
  }

  getDados = async () => {
    this.setState({showProgress: true});
    await axios.get(`${REQUEST_URL}vendas/${this.state.ano}-01-01/${this.state.vendedor}`).then(async (response) => {
      await this.setState({dadosVenda: response.data});
      this.construirGrafico();
    }).catch((error) => {
      console.log(error);
    }).finally(() => {
      this.setState({showProgress: false});
    });
  }

  gerarLabels() {
    let labels = [];
    let inicioAno = new Date(this.state.ano, 0, 1);
    for (let mes = 0; mes < 12; mes++) {
      let mesesAno = new Date(
        inicioAno.setMonth(mes)
      );
      labels.push({ data: mesesAno });
    }
    return labels;
  }

  gerarArrayDados() {
    const labels = this.gerarLabels();
    for (let i = 0; i < labels.length; i++) {
      labels[i].meta = 0;
      labels[i].precoVendas = 0;
      labels[i].metaVariavel = 0;
    }
    return labels;
  }

  sqlDateToJsDate(stringDt) {
    return new Date(Number(stringDt.substring(0, 4)), Number(stringDt.substring(5, 7)) - 1, Number(stringDt.substring(8, 10)));
  }

  construirGrafico() {
    const dadosGrafico = this.gerarArrayDados();

    this.state.dadosVenda.forEach(venda => {
      const dataVenda = this.sqlDateToJsDate(venda.DtEmissaoOp);
      dadosGrafico.forEach(dadoGrafico => {
        if (
          dadoGrafico.data.getMonth() === dataVenda.getMonth() &&
          dadoGrafico.data.getFullYear() === dataVenda.getFullYear()
        ) {
          dadoGrafico.precoVendas += venda.PrecoTotal;
        }
      });
    });

    let vendaAcumulada = 0;
    const metaAnual = this.state.metaAnual;
    let mesesProFim = 12;
    dadosGrafico.forEach(dadoGrafico => {
      dadoGrafico.metaVariavel = (metaAnual - vendaAcumulada) / mesesProFim;
      dadoGrafico.meta = metaAnual / 12;
      vendaAcumulada += dadoGrafico.precoVendas;
      if (dadoGrafico.precoVendas > 0) mesesProFim--;
    });

    let meses = [];
    let meta = [];
    let real = [];
    let ideal = [];

    dadosGrafico.forEach(dadoGrafico => {
      meses.push(
        dadoGrafico.data.toLocaleDateString("pt-br", {
          year: "numeric",
          month: "short"
        })
      );
      meta.push(dadoGrafico.meta.toFixed(2));
      real.push(dadoGrafico.precoVendas.toFixed(2));
      ideal.push(dadoGrafico.metaVariavel.toFixed(2));
    });

    const chartData = {
      labels: meses,
      datasets: [
        {
          fill: false,
          label: "meta",
          data: meta,
          backgroundColor: "#2D8DD6",
          borderColor: "#2D8DD6",
          cubicInterpolationMode: "monotone"
        },
        {
          fill: false,
          label: "real",
          data: real,
          backgroundColor: "#FF8B58",
          borderColor: "#FF8B58"
        },
        {
          fill: false,
          label: "ideal",
          data: ideal,
          backgroundColor: "#09B22E",
          borderColor: "#09B22E"
        }
      ]
    };

    this.setState({chartData: chartData});
  }

  render(){
    return (
      <div className="panel panel-default col-md-12">
        <div className="panel-heading" style={{textAlign:"left", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
          META IDEAL VIGENTE
          {
            this.state.showProgress ?
              <div><i style={{marginRight: 5}} className="fa fa-spinner fa-spin"></i></div> :
              <span className="clickable panel-toggle panel-button-tab-left panel-collapsed"><em className="fa fa-toggle-down"></em></span>
          }
        </div>
        <div className={"panel-body " + (this.state.showProgress ? 'div-desativada' : '')} style={{display:"none"}}>
          <h3>Grafico</h3>
          <div className="chart">
            <Line data={this.state.chartData} options={OPTIONS}/>
          </div>

          <h3>Tabela</h3>
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead>
              <tr>
                <th></th>
                {
                  this.state.chartData.labels ?
                    this.state.chartData.labels.map((l,i)=>(
                      <th key={i}>{l}</th>
                    ))
                    : null
                }
              </tr>
              </thead>
              <tbody>
              <tr style={{backgroundColor: "#2D8DD6",color:"#fff"}}>
                <td>meta</td>
                {
                  this.state.chartData.labels ?
                    this.state.chartData.labels.map((i,l)=>(
                      <td key={l}>
                        R$:
                        {
                          this.state.chartData.datasets[0].data[l]?
                            this.numberToReal(Number(this.state.chartData.datasets[0].data[l])):
                            null
                        }
                      </td>
                    ))
                    :null
                }
              </tr>
              <tr style={{backgroundColor: "#FF8B58",color:"#fff"}}>
                <td>real</td>
                {
                  this.state.chartData.labels ?
                    this.state.chartData.labels.map((i,l)=>(
                      <td key={l}>
                        R$:
                        {
                          this.state.chartData.datasets[1].data[l] ?
                            this.numberToReal(Number(this.state.chartData.datasets[1].data[l])):
                            null

                        }
                      </td>
                    ))
                    :null
                }
              </tr>
              <tr style={{backgroundColor: "#09B22E",color:"#fff"}}>
                <td>ideal</td>
                {
                  this.state.chartData.labels ?
                    this.state.chartData.labels.map((i,l)=>(
                      <td key={l}>
                        R$:
                        {
                          this.state.chartData.datasets[2].data[l] ?
                            this.numberToReal(Number(this.state.chartData.datasets[2].data[l])):
                            null

                        }
                      </td>
                    ))
                    :null
                }
              </tr>
              </tbody>
            </table>
          </div>
        </div>

      </div>

    )
  }
}
export default MetaVariavel;
