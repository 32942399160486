import React,{Component} from 'react';
import axios from 'axios';
import {REQUEST_URL} from '../config/urls';
import ReactTable from "react-table";
import 'react-table/react-table.css';
const FileDownload = require('js-file-download');

class Clientes extends Component{
  constructor(props){
    super(props);
    this.state = {
      clientesUltOrc:[],
      clientesUltOrcTodos:[],
      selectValueClientes:"0",
      selecionado:{},
      orcSelecionado:{},
      orcamentos:[],
      ops:[],
      filtroStatus:"todos",
      progressExport: false
    }
  }
  componentDidMount (){
    this.setState({clientesUltOrc: this.props.clientesUltOrc, clientesUltOrcTodos: this.props.clientesUltOrc, vendedor: this.props.vendedor});
  }
  changeSelectClientes(event){
    //  console.log(event.target.value);
    this.setState({selectValueClientes:event.target.value});
    this.filtrarClientes(event.target.value);
  }
  filtrarClientes(meses){
    if(meses !== -1)
      this.setState({clientesUltOrc:this.state.clientesUltOrcTodos.filter((c)=>{return c.MesesDoUltimoOrc >= meses})});
    else
      this.setState({clientesUltOrc:this.state.clientesUltOrcTodos.filter((c)=>{return c.MesesDoUltimoOrc <= 1})});


  }
  IsActv(c){
    return (c.MesesDoUltimoOrc < 12 /* ||  ( new Date(c.InsDt) >= new Date(new Date().setFullYear(new Date().getFullYear() - 1)))*/ );
  }
  buscarOrcamentos(){
    console.log("###################################################################################################################");
    let self = this;
    console.log(this.state.selecionado);
    axios.get(REQUEST_URL+'orcamentos/'+this.state.selecionado.CodCliente)
      .then(function (response) {
        self.setState({orcamentos: response.data});
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  buscarOps(){

    let self = this;
    axios.get(REQUEST_URL+'ops/'+this.state.orcSelecionado.NumOrcamento)
      .then(function (response) {
        self.setState({ops: response.data});
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  getSituacaoOrc(cod){
    switch (cod) {
      case 100:
        return "Op Parcial";
      case 200:
        return "Em Elaboração";
      case 400:
        return "Proposta gerada";
      case 950:
        return "Enviado Produção";
      case 900:
        return "Cancelado";
      default:
        return "n sei";
    }
  }
  filtrarStatus(status){
    //console.log(this.state.clientesUltOrc);
    if(status != this.state.filtroStatus){
      //console.log(status);
      this.setState({filtroStatus:status});
      if(status !== "todos"){
        this.setState({
          clientesUltOrc:this.state.clientesUltOrcTodos.filter((c)=>{return c.StatusCliente.toUpperCase() === status.toUpperCase()})
        })
      }else{
        this.setState({
          clientesUltOrc:this.state.clientesUltOrcTodos
        })
      }
    }

  }

  downloadXLSX() {
    this.setState({progressExport: true});
    axios({
      url: REQUEST_URL+'exportar-cateira',
      method: 'POST',
      data: {
        vendedor: this.state.vendedor
      },
      responseType: 'blob',
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Clientes.xlsx');
      document.body.appendChild(link);
      link.click();
      this.setState({progressExport: false});
    }).catch((error) => {
      console.log(error);
      alert('Erro ao baixar a planilha');
    });
  }

  render(){
    return (
      <div>
        <div id="modalCarteira" ref="modal" className="modal fade" role="dialog">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">&times;</button>
                <h4 className="modal-title"> Carteira de clientes </h4>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div style={{display: "flex", justifyContent: "center", marginBottom: 15}}>
                    <div className="btn-group" role="group" aria-label="Basic example">
                      <button type="button" onClick={this.filtrarStatus.bind(this,"suspect")} className={`btn btn-secondary ${this.state.filtroStatus === "suspect" ? "active":""}`}>Suspect</button>
                      <button type="button" onClick={this.filtrarStatus.bind(this,"prospect")} className={`btn btn-secondary ${this.state.filtroStatus === "prospect" ? "active":""}`}>Prospect</button>
                      <button type="button" onClick={this.filtrarStatus.bind(this,"cliente")} className={`btn btn-secondary ${this.state.filtroStatus === "cliente" ? "active":""}`}>Cliente</button>
                      <button type="button" onClick={this.filtrarStatus.bind(this,"todos")} className={`btn btn-secondary ${this.state.filtroStatus === "todos" ? "active":""}`}>Todos</button>
                    </div>
                  </div>
                </div>
                <ReactTable
                  previousText= {'Anterior'}
                  nextText= {'Proximo'}
                  noDataText= {'Nada foi encontrado'}
                  pageText= {'Pagina'}
                  ofText= {'de'}
                  rowsText= {'linhas'}
                  filterable
                  defaultPageSize={10}
                  showPageSizeOptions= {false}
                  data={this.state.clientesUltOrc}
                  columns={[
                    {
                      Header: 'Código',
                      accessor: 'CodCliente'
                    }, {
                      Header: 'Nome',
                      accessor: 'Nome'
                    }, {
                      Header: 'Telefone',
                      accessor: 'Telefone'
                    }, {
                      Header: 'Status',
                      accessor: 'StatusCliente'
                    }, {
                      Header: 'Data inclusão',
                      accessor: 'InsDt',
                      Cell: row => row.value != null ? (new Date(row.value).toLocaleDateString()) : ''
                    }, {
                      Header: 'Orçamentos',
                      Cell: row => (
                        <button className="btn btn-secondary" data-toggle="modal" data-target="#modal" onClick={(e) => {this.setState({selecionado: row.original}, () => this.buscarOrcamentos())}}>Orcamentos</button>
                      )
                    }
                  ]}
                />
                <div style={{margin: "2rem 0"}}>
                  <button onClick={this.downloadXLSX.bind(this)} className="btn btn-warning btn-lg">
                    <i className={this.state.progressExport ? 'fa fa-refresh fa-spin' : 'fa fa-download'} aria-hidden="true" /> Exportar dados
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="modal" className="modal fade" role="dialog">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">&times;</button>
                <h4 className="modal-title">Orcamentos {this.state.selecionado.Nome}</h4>
              </div>
              <div className="modal-body">
                <ReactTable
                  previousText= {'Anterior'}
                  nextText= {'Proximo'}
                  noDataText= {'Nada foi encontrado'}
                  pageText= {'Pagina'}
                  ofText= {'de'}
                  rowsText= {'linhas'}
                  filterable
                  defaultPageSize={10}
                  showPageSizeOptions= {false}
                  defaultPageSize={5}
                  data={this.state.orcamentos}
                  columns={[{
                    Header: 'Num',
                    accessor: 'NumOrcamento'
                  }, {
                    Header: 'Titulo',
                    accessor: 'Titulo'
                  }, {
                    Header: 'Emissão',
                    accessor: 'DtEmissao',
                    Cell: row => (
                      new Date(row.value).toLocaleDateString()
                    )
                  }, {
                    Header: 'SPreço',
                    accessor: 'SPreco',
                    Cell: row => (
                      row.value.toFixed(2)
                    )
                  },{
                    Header: 'Data de Lib Prod',
                    accessor: 'DtLibProducao',
                    Cell: row => {
                      if (new Date(row.value)> new Date('2001','01','01')) {
                        return new Date(row.value).toLocaleDateString()
                      }
                    }
                  },{
                    filterable:false,
                    Header: '',
                    Cell: row => (
                      <button
                        onClick={(e) => {
                          console.log(row.original);
                          this.setState({orcSelecionado:row.original},()=>
                            this.buscarOps()
                          );
                        }}>OPs</button>
                    )
                  }]}
                />
              </div>
              {
                this.state.ops.length > 0 ?
                  <div className="modal-body" style={{ maxHeight: "200px", overflowY:"scroll"}}>
                    <table className="table">
                      <thead>
                      <tr>
                        <th>NumOP</th>
                        <th>Descrição</th>
                        <th>Emissão</th>
                        <th>Valor</th>
                      </tr>
                      </thead>
                      <tbody>
                        {
                          this.state.ops.map(op => (
                            <tr key={op.NumOrdem}>
                              <td>{op.NumOrdem}</td>
                              <td>{op.DescrProduto}</td>
                              <td>{new Date(op.DtEmissaoOp).toLocaleDateString()}</td>
                              <td>{op.PrecoTotal.toFixed(2)}</td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </table>
                  </div>
                : null
              }
              <div className="modal-footer">
                <button type="button" className="btn btn-default" data-dismiss="modal">Fechar</button>
              </div>
            </div>

          </div>
        </div>
      </div>
    )
  }
}
export default Clientes;
