import React,{Component} from 'react';
import axios from "axios";
import FunnelGraph from 'funnel-graph-js'
import { REQUEST_URL } from "../../config/urls";

class FunilVendas extends Component {

  constructor(props){
    super(props);
    this.state = {
      clientesComOrcEmNegociacao:[],
      clientesComComprasRealizadas:[],
      buscouDados:false,
      periodo: 0
    }
  }

  componentDidMount(){
    //this.buscarDados();
    //this.carregarGrafico();
  }

  componentWillReceiveProps(nextProps){
    if (this.state.periodo !== 0) {
      this.carregarGrafico(this.state.periodo);
    }
  }

  numberToReal(numero) {
    var NewNumero = numero.toFixed(2).split('.');
    NewNumero[0] =  NewNumero[0].split(/(?=(?:...)*$)/).join('.');
    return NewNumero.join(',');
  }
  buscarDados(){
    if(!this.state.buscouDados){
      let self = this;
      axios.get(REQUEST_URL + "clientesComOrcEmNegociacao/" + self.props.vendedor)
          .then(function(response) {
            self.setState({ clientesComOrcEmNegociacao: response.data,buscouDados:true });
          })
          .catch(function(error) {
              console.log(error);
          });
      axios.get(REQUEST_URL + "clientesComComprasRealizadas/" + self.props.vendedor)
          .then(function(response) {
            self.setState({ clientesComComprasRealizadas: response.data });
          })
          .catch(function(error) {
              console.log(error);
          });

    }
  }

  carregarGrafico(periodo) {
    this.setState({periodo: periodo});
    axios.get(REQUEST_URL + `filtro-vendas/${periodo}/${this.props.vendedor}`)
      .then(function(response) {
        const dados = response.data;
        document.querySelector('.funnel').innerHTML = '';
        const tamanho = {
          largura: 0,
          altura: 0
        }

        if (window.screen.width <= 320) {
          tamanho.largura = 250;
          tamanho.altura = 150;
        } else if (window.screen.width <= 600) {
          tamanho.largura = 300;
          tamanho.altura = 200;
        } else if (window.screen.width <= 1024) {
          tamanho.largura = 600;
          tamanho.altura = 300;
        } else {
          tamanho.largura = 800;
          tamanho.altura = 400;
        }

        var graph = new FunnelGraph({
          container: '.funnel',
          gradientDirection: 'horizontal',
          data: {
            labels: ['Cliente(s)', 'Orçamento(s)', 'Venda(s)'],
            colors: ['#e53935', '#FDD835', '#43A047'],
            values: [dados[0].QTD_Clientes, dados[0].QTD_ORC, dados[0].QTD_OPs]
          },
          displayPercent: true,
          direction: 'horizontal',
          width: tamanho.largura,
          height: tamanho.altura,
        });

        graph.draw();
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  render(){
    return (
      <div className="panel panel-default col-md-12  ">

        <div className="panel-heading" style={{textAlign:"left"}}>
          FUNIL DE VENDAS
          <span className="pull-right clickable panel-toggle panel-button-tab-left panel-collapsed" onClick={this.carregarGrafico.bind(this, 3)}><em className="fa fa-toggle-down"></em></span>
        </div>

        <div className="panel-body" style={{display:"none"}}>
          <h3>Funil de clientes inclusos</h3>
          <div style={{paddingBottom: '1.5rem', paddingTop: '1.5rem'}}>
            <div style={{display: 'flex', justifyContent: 'center', flexWrap: 'wrap'}} className="btn-group" role="group" >
              <button type="button" onClick={this.carregarGrafico.bind(this, 1)} className={`btn btn-secondary ${this.state.periodo === 1 ? "active":""}`}>1 mês</button>
              <button type="button" onClick={this.carregarGrafico.bind(this, 3)} className={`btn btn-secondary ${this.state.periodo === 3 ? "active":""}`}>3 meses</button>
              <button type="button" onClick={this.carregarGrafico.bind(this, 6)} className={`btn btn-secondary ${this.state.periodo === 6 ? "active":""}`}>6 meses</button>
              <button type="button" onClick={this.carregarGrafico.bind(this, 9)} className={`btn btn-secondary ${this.state.periodo === 9 ? "active":""}`}>9 meses</button>
              <button type="button" onClick={this.carregarGrafico.bind(this, 12)} className={`btn btn-secondary ${this.state.periodo === 12 ? "active":""}`}>12 meses</button>
              <button type="button" onClick={this.carregarGrafico.bind(this, 24)} className={`btn btn-secondary ${this.state.periodo === 24 ? "active":""}`}>24 meses</button>
            </div>
          </div>
          <div style={{display: 'flex', justifyContent: 'center', padding: '3rem'}}>
            <div className="funnel">
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default FunilVendas;
