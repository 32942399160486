
import {applyMiddleware, createStore } from 'redux'
import appReducer from '../reducers/appReducer'

// Logger with default options
//import logger from 'redux-logger'
const store = createStore(
  appReducer,
  applyMiddleware()
)

export default store;
